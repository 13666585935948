import { FacetService, GetFacetsResponse } from "@rulla/features";
import { QueryCacheKeys } from "@rulla/shared";
import { ApiError } from "@rulla/shared/infrastructure";
import { useQuery } from "@tanstack/react-query";

import { HttpErrorResponse } from "@newsh/base";

interface UseFacetsProps {
  retailerId?: string;
  enabled: boolean;
}

export const useFacets = ({ enabled, retailerId }: UseFacetsProps) => {
  return useQuery<GetFacetsResponse, ApiError>([QueryCacheKeys.Facets, retailerId], () =>
    FacetService.getFacets(retailerId).catch((error) => {
      throw new ApiError(new HttpErrorResponse(error));
    }),
    { enabled }
  );
};
