export const CookieService = {
  get: (name: string) => {
    return getCookie(name);
  },
  delete: (name: string, path?: string, domain?: string) => {
    deleteCookie(name, path, domain);
  },
  deleteAll: () => {
    clearAllCookies();
  },
  set: (name: string, value: string, expires: Date, path = '/', domain?: string) => {
    document.cookie =
      name +
      '=' +
      value +
      `;expires=` +
      expires.toUTCString() +
      ';path=' + 
      path +
      (domain ? ';domain=' + domain : '');
  }
};

function getCookie(name: string): string | undefined {
  return document.cookie.split(';').find((c) => {
    return c.trim().startsWith(name + '=');
  });
}

function deleteCookie(name: string, path = '/', domain?: string) {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      ';path=' + 
      path +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

function clearAllCookies() {
  const excludeList = ['__Host-CSRF', 'X-CSRF-Token'];
  const cookies = document.cookie.split('; ');
  for (let i = 0; i< cookies.length; i++) {
    const [cookieName] = cookies[i].split('=');
    
    if (!excludeList.includes(cookieName)) {
      deleteCookie(cookieName, "/");
      deleteCookie(cookieName, "/", window.location.hostname);
    }
  }
}
