import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";

export function NavMenu() {
  const [open, setOpen] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const [shouldShowBackground, setShouldShowBackground] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (shouldBeOpen: boolean | null) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (shouldBeOpen !== null) {
      setOpen(shouldBeOpen);
    } else {
      setOpen(!open);
    }
  };

  const handleGoToMainPage = () => {
    navigate(`/`);
  };

  const handleCommonQuestionsButtonClick = (): void => {
    setOpen(false);
    navigate(`/vanliga-fragor`);
  };

  const handleGoToRullaPageClick = (): void => {
    setOpen(false);
    const rullaURL = "https://rulla.se";
    window.open(rullaURL, "_blank");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShouldShowShadow(true);
        setShouldShowBackground(true);
      } else {
        setShouldShowShadow(false);
        setShouldShowBackground(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 999) {
        setOpen(false); // Automatically close the drawer when screen size is over 999px
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      elevation={shouldShowShadow ? 4 : 0}
      style={{
        background: shouldShowBackground ? "#FCFCFC" : "transparent",
        transition: "box-shadow 0.3s ease",
        boxShadow: shouldShowShadow ? "0 10px 50px rgba(0, 0, 0, 0.1)" : "none",
      }}
      position="fixed"
      sx={{
        backgroundColor: "#FCFCFC",
        zIndex: 1300,
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters
        sx={{
          height: "5.625rem",
          maxWidth: "100rem",
          paddingX: "5.625rem",
          width: "100%",
          mx: "auto",
          "@media (max-width: 1023px)": {
            maxWidth: "37.5rem",
            height: "3rem",
            paddingX: 0,
          },

          "@media (max-width: 640px)": {
            maxWidth: "26.25rem",
            height: "3rem",
            paddingX: 0,
          },
          "@media (max-width: 480px)": {
            maxWidth: "20rem",
            height: "3rem",
            paddingX: 0,
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Button
            onClick={handleGoToMainPage}
            sx={{
              padding: 0,
              width: "7.421rem",
              height: "1.875rem",
              "@media (max-width: 900px)": {
                width: "5.937rem",
                height: "1.5rem",
              },
            }}
          >
            <img
              src="/rulla_pos_RGB.png"
              alt="rulla logo"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Button>
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          <Button
            onClick={handleCommonQuestionsButtonClick}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              lineHeight: "1.5rem",
              letterSpacing: "-1",
              color: "#000",
              display: { xs: "none", lg: "inline" },
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            Vanliga frågor
          </Button>
          <Button
            onClick={handleGoToRullaPageClick}
            disableElevation={true}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              lineHeight: "1.5rem",
              letterSpacing: "-1",
              color: "#1D818E",
              display: { xs: "none", lg: "inline" },
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            Rulla.se
          </Button>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(null)}
          sx={{
            display: { xs: "block", lg: "none" },
            color: "#000",
          }}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Drawer
          anchor="right" //from which side the drawer slides in
          variant="temporary" //if and how easily the drawer can be closed
          open={open} //if open is true, drawer is shown
          onClose={toggleDrawer(false)} //function that is called when the drawer should close
          PaperProps={{
            sx: {
              width: "100%",
              backgroundColor: "#FCFCFC",
            },
          }}
        >
          <Box
            sx={{
              pl: "2.5rem",
            }}
          >
            <ListItemButton
              sx={{ mt: 6 }}
              onClick={handleCommonQuestionsButtonClick}
            >
              <ListItemText primary="Vanliga frågor" />
            </ListItemButton>
            <ListItemButton
              sx={{ mt: 3, color: "#1D818E" }}
              onClick={handleGoToRullaPageClick}
            >
              <ListItemText primary="Rulla.se" />
            </ListItemButton>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
