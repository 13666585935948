import { HttpClient as http } from '@newsh/base';

export enum LogLevel {
  Information = 1,
  Warning = 2,
  Error = 3,
  Fatal = 4,
}

interface LogRequest {
  errorMessage?: string;
  level: LogLevel;
  message: string;
  source: string;
}

export const LogService = {
  log: async (logRequest: LogRequest): Promise<void> => {
    try {
      await http.post<void>(`/api/log`, logRequest);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Failed to log', error);
    }
  },
};
