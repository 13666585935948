import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { VehicleAvatarCard } from "@rulla/features";
import { Reservation } from "@rulla/shared";

export function BilbolagetEmbeddedConfirmationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const reservation: Reservation = location.state?.reservation;

  const handleBackToRullaButtonClick = () => {
    navigate("/");
  };

  if (!reservation) {
    window.location.replace(`/`);
    return <></>;
  }

  return (
    <div className="relative w-full flex-1 h-screen font-embeddedFont mt-4">
      <div className="grid place-items-center text-heading-small sm:text-heading">
        <h1>Tack!</h1>
        <div className="container w-[20.4375rem] sm:w-[38rem] text-paraMidReg sm:tetx-paraLargeReg pt-[0.5rem] sm:pt-[1.5rem]">
          <p className="text-center">
            Din bokningsförfrågan är skickad och bilhandlaren kommer att
            kontakta dig inom kort för att bekräfta bokningen.
          </p>
        </div>
        <div className="pt-[2rem] flex items-center justify-center">
          <VehicleAvatarCard
            title={reservation.vehicle.title}
            pricePerMonth={reservation.totalPricePerMonth}
            avatarSource={reservation.vehicle.imageSource}
            startDate={reservation.startDate}
          />
        </div>
        <div className=" pt-[1.5rem] pb-6 sm:pt-[2.25rem] flex justify-center ">
          <Button
            disableElevation={true}
            sx={{
              width: "20rem",
              height: "3.5rem",
              color: "#FEF6F9",
              bgcolor: "#C8372F",
              fontFamily: '"Source Sans Pro"',
              fontSize: "1rem",
              fontWeight: "600",
              lineHeight: "1.5rem",
              letterSpacing: "-1",
              "@media (max-width: 600px)": {
                width: "20.4375rem",
                height: "3rem",
              },
              "&:hover": {
                backgroundColor: "#C8372F",
                boxShadow:
                  "0px 0.7px 20px 0 #C8372F05, 0px 0.5px 20px 0 #C8372F08",
              },
              textTransform: "none",
              boxShadow:
                "0px 0.7px 20px 0 #C8372F05, 0px 0.5px 20px 0 #C8372F08",
            }}
            variant="contained"
            onClick={handleBackToRullaButtonClick}
          >
            Tillbaka
          </Button>
        </div>
      </div>
    </div>
  );
}
