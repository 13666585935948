import React from "react";
import { CircularProgress, Collapse, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AdditionalService } from "@rulla/shared";

import { StepIndicator } from "../../reservation/components/step-indicator";

export interface AdditionalServiceListProps {
  additionalServices: AdditionalService[] | undefined;
  selectedAdditionalServiceIds: string[];
  onAdditionalServiceClick: (additionalServiceId: string) => void;
  isLoading: boolean;
}

export function AdditionalServiceList({
  additionalServices,
  selectedAdditionalServiceIds,
  onAdditionalServiceClick,
  isLoading,
}: AdditionalServiceListProps) {
  const [openedAdditionalServiceIds, setOpenedAdditionalServiceIds] =
    React.useState<string[]>([]);
  const onReadMoreClick = (additionalServiceId: string) => {
    if (openedAdditionalServiceIds.includes(additionalServiceId)) {
      setOpenedAdditionalServiceIds(
        openedAdditionalServiceIds.filter((id) => id !== additionalServiceId)
      );
    } else {
      setOpenedAdditionalServiceIds([
        ...openedAdditionalServiceIds,
        additionalServiceId,
      ]);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack rowGap={0.5}>
      {additionalServices && additionalServices.length > 0 && (
        <div className="flex flex-row items-center mt-10 gap-3">
          <StepIndicator label="3" />
          <h3 className="text-h4-reg">Välj tillägg</h3>
        </div>
      )}
      {additionalServices &&
        additionalServices.map((additionalService) => (
          <Stack
            className="border-b border-[#F5F5F5] py-4"
            rowGap={0.5}
            key={additionalService.id}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">{additionalService.title}</Typography>
              <div className="flex flex-shrink-0 w-auto h-min">
                {additionalService.pricePerMonth === 0 ? (
                  <div className="h-8 text-base leading-6 text-[#E9457E] bg-[rgba(233,69,126,0.05)] font-semibold tracking-tighter pointer-events-none flex items-center justify-center py-1.5 px-2 rounded-md">
                    Ingår
                  </div>
                ) : selectedAdditionalServiceIds.includes(
                    additionalService.id
                  ) ? (
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: "#35B6B21A",
                      color: "#1D818E",
                      borderRadius: "5rem",
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: "600",
                      lineHeight: "1.5rem",
                      letterSpacing: "-0.01rem",
                      "&:hover": {
                        backgroundColor: "#35B6B23A",
                        color: "#1A7A7D",
                      },
                    }}
                    onClick={() =>
                      onAdditionalServiceClick(additionalService.id)
                    }
                  >
                    Ta bort
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() =>
                      onAdditionalServiceClick(additionalService.id)
                    }
                    sx={{
                      backgroundImage:
                        "linear-gradient(to bottom, #35B6B2, #1F8E9E)",
                      color: "white",
                      borderRadius: "5rem",
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: "600",
                      lineHeight: "1.5rem",
                      letterSpacing: "-0.01rem",
                      boxShadow:
                        "0px 2px 5px 0px rgba(0, 0, 0, 0.09), 0px 2px 14px 1px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03)",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to bottom, #2FA5A2, #1A7A7D)",
                      },
                    }}
                  >
                    Lägg till
                  </Button>
                )}
              </div>
            </Stack>
            <Typography
              sx={{
                typography: { xs: "h5PinkSmall", lg: "h5Pink" },
              }}
            >
              {additionalService.pricePerMonth} kr/månad
            </Typography>
            <Typography sx={{ typography: "paraMidReg" }}>
              {additionalService.shortDescription}
            </Typography>
            {additionalService.description && (
              <>
                <Button
                  sx={{
                    color: "#1D818E",
                    textTransform: "none",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    lineHeight: "1.75rem",
                    alignSelf: "flex-start",
                  }}
                  variant="text"
                  onClick={() => onReadMoreClick(additionalService.id)}
                >
                  Läs mer
                </Button>
                <Collapse
                  in={openedAdditionalServiceIds.includes(additionalService.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Typography sx={{ typography: "paraMidReg" }}>
                    {additionalService.description}
                  </Typography>
                </Collapse>
              </>
            )}
          </Stack>
        ))}
    </Stack>
  );
}
