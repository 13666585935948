import { HttpErrorResponse } from '@newsh/base';

import { ApiError } from '../infrastructure';

export interface ErrorDetails {
  correlationId?: string;
  details?: string;
  httpStatus?: number;
  reason?: 'ERROR_PAGE_CRASH' | 'ERROR_PAGE_NOT_FOUND';
  title?: string;
  traceId?: string;
  uri?: string;
}

export const ErrorUtil = {
  getErrorDetails: (error: any, state: any): ErrorDetails => {
    if (error instanceof HttpErrorResponse) {
      // AppInitializer will redirect to error-page in case of HttpErrorResponse during app bootstrapping (ie. fetching user session or authorization fails).
      const apiError = new ApiError(error);
      return {
        correlationId: state?.['response']?.['headers']?.['x-correlation-id'],
        details: apiError.detail?.slice(0, 1500),
        httpStatus: apiError.httpStatus,
        title: apiError.title,
        traceId: apiError.traceId,
        uri: apiError.uri,
      };
    } else if (error instanceof Error) {
      // AppError boundary will redirect to error-page when react components crashes during rendering.
      return {
        correlationId: state?.['response']?.['headers']?.['x-correlation-id'],
        details: error.stack?.slice(0, 1500),
        reason: 'ERROR_PAGE_CRASH',
        title: 'JavaScript - ' + error.name,
        uri: (error as any)['path'],
      };
    } else if (state?.['pageNotFound']) {
      // Shell will redirect to error-page as a fall-back used for default paths (page not found).
      return {
        correlationId: state?.['response']?.['headers']?.['x-correlation-id'],
        httpStatus: 404,
        reason: 'ERROR_PAGE_NOT_FOUND',
        title: 'Page not found',
        uri: state['path'],
      };
    }
    return {
      correlationId: state?.['response']?.['headers']?.['x-correlation-id'],
      httpStatus: state?.['response']?.['status'],
      reason: 'ERROR_PAGE_CRASH',
      title: state?.['response']?.['statusText'],
      uri: state['path'],
    };
  },
};
