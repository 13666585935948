import { Link } from "react-router-dom";
import { useConsent } from '@rulla/shared';

export function CookiePolicy() {
  const { openConsent } = useConsent();
  return (
    <div className="container font-rullaFont max-w-[58.54rem] mx-auto py-[8vw] pt-[7rem] lg:pt-0 my-0 lg:my-[10vw]">
      <div className="flex flex-col mx-[1.563rem] lg:mx-0">
        <h1 className="text-headingLargeSmall lg:text-headingLarge">
          Cookie-policy för Rulla månadsvis (manadsvis.rulla.se)
        </h1>
        <em className="text-paraLargeReg">Senast uppdaterad: 04/10/2023</em>
      </div>
      <div className="flex flex-col items-start gap-[0.5rem] mt-[1.688rem] mx-[1.563rem] lg:mx-0">
        <h2 className="text-heading4Small lg:text-heading4">
          1. Vad är cookies?
        </h2>
        <p className="text-paraLargeReg">
          Cookies är små textfiler som sparas på din dator eller mobila enhet
          när du besöker vår webbplats. Dessa filer innehåller information som
          används för att förbättra din upplevelse och för att hjälpa oss att
          förbättra vår webbplats.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          2. Syftet med cookies
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi använder cookies för följande ändamål:
        </p>
        <ul
          className="text-paraLargeReg"
          style={{ listStylePosition: "inside", listStyleType: "disc" }}
        >
          <li>
            Förbättra prestanda: Cookies hjälper oss att optimera webbplatsens
            prestanda och snabbhet.
          </li>
          <li>
            Anpassa din upplevelse: Vi använder cookies för att komma ihåg dina
            inställningar och preferenser, vilket gör det möjligt för oss att
            erbjuda dig en mer personlig användarupplevelse.
          </li>
          <li>
            Förstå användarbeteende: Genom att samla in anonym data om hur
            besökare använder vår webbplats kan vi analysera och förbättra dess
            innehåll och funktioner.
          </li>
        </ul>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          3. Vilka typer av cookies som används
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi använder följande typer av cookies:
        </p>
        <ul
          className="text-paraLargeReg"
          style={{ listStylePosition: "inside", listStyleType: "disc" }}
        >
          <li>
            Nödvändiga cookies: Dessa cookies är nödvändiga för att du ska kunna
            använda webbplatsens funktioner. De används för att förhindra
            attacker.
          </li>
          <li>
            Sessionscookies: Dessa cookies raderas när du stänger din webbläsare
            och används för att säkerställa att du kan använda webbplatsens
            funktioner under din besökssession.
          </li>
          <li>
            Analyscookies: Dessa cookies, ifall du accepterar dem, samlar in
            anonym data om hur besökare använder vår webbplats. De används för
            att förbättra webbplatsens funktioner och innehåll.
          </li>
        </ul>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          4. Samtycke
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi respekterar ditt val. Genom att använda vår webbplats samtycker du
          till användningen av cookies enligt denna policy. Om du inte samtycker
          till användningen av cookies, vänligen ändra dina cookie-inställningar
          i din webbläsare eller avvisa cookies när du besöker vår webbplats.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          5. Cookieinställningar
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Du kan ändra dina cookie-inställningar när som helst. Klicka
          <span
            className="text-paraLargeReg"
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openConsent}
          > här </span>
          för att justera dina preferenser.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          6. Lagringsperiod
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi använder oss av både sessionscookies och cookies som lagras under
          en längre period. Sessionscookies raderas när du stänger din
          webbläsare. Cookies som lagras under en längre period raderas
          automatiskt efter 12 månader.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          7. Säkerhet och integritet
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi försäkrar dig om att cookies inte samlar in personlig information
          direkt och att din integritet är av yttersta vikt för oss. Läs vår
          <Link style={{ fontWeight: "600" }} to={`/integritetspolicy`}>
            {" "}
            integritetspolicy{" "}
          </Link>
          för mer information om hur vi hanterar personuppgifter.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          8. Tredjepartscookies
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Rulla månadsvis har inga tredjepartscookies.
        </p>
      </div>
    </div>
  );
}
