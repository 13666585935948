import { Route } from 'react-router-dom';
import { PageBase } from '@rulla/shared';

import { CustomRouteObject } from './types';

export const makeRoute = (route: CustomRouteObject, index: number) => {
  if (route.children) {
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <PageBase>{route.element}</PageBase>
          // <PageBase permissions={route.permissions}>{route.element}</PageBase>
        }
      >
        {route.children.map(makeRoute)}
      </Route>
    );
  }

  return (
    <Route
      key={index}
      path={route.path}
      element={
        <PageBase>{route.element}</PageBase>
        // <PageBase permissions={route.permissions}>{route.element}</PageBase>
      }
      index={route.index}
    />
  );
};

export function createRoutePathWithParams(base: string, params: object): string {
  return base + Object.keys(params).map(key => `/:${key}`).join('');
}
