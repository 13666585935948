import { HttpClient as http } from '@newsh/base';

import { CookieService } from '../cookie/cookie.service';

const antiforgeryCookieName = '__Host-CSRF';
const antiforgeryTokenName = 'X-CSRF-Token';

export const AntiforgeryInterceptor = {
  UseAntiforgeryInterceptors: () => {
    http.interceptors.request.use((requestConfig) => {
      const antiforgeryCookie = CookieService.get(antiforgeryCookieName);

      if (antiforgeryCookie && requestConfig.headers) {
        const antiforgeryValue = antiforgeryCookie.split('=')[1];
        requestConfig.headers[antiforgeryTokenName] = antiforgeryValue;
      }

      return requestConfig;
    });
  },
};
