import { useNavigate,useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress } from "@mui/material";
import Button from '@mui/material/Button';
import {
  BottomBanner,
  BottomNavMenu,
  CommonQuestionsDropDown,
  ImageCarousel,
  PriceContainer,
  RetailerBranchInformation,
  VehicleEquipment,
  VehiclePropertiesTable,
  VehicleTitle,
} from "@rulla/features";
import { VehicleInfoParams } from "@rulla/routes/public-routes";
import { ApiErrorCode, DetailedVehicle, useVehicle } from "@rulla/shared";

export function VehicleInfo() {
  const navigate = useNavigate();
  const { vehicleId } = useParams<VehicleInfoParams>();
  const { data, isLoading, error } = useVehicle({
    vehicleId,
    enabled: true,
  });
  
  const handleBackToRullaButtonClick = () => {
    navigate("/");
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-[5.625rem] max-w-[100rem] mx-auto pb-[6.31rem]">
        <CircularProgress />
      </div>
    );
  }

  if (error && error.errorCode === ApiErrorCode.VehicleStale) {
    return (
      <div className="relative w-full">
        <div className="container font-rullaFont pt-[5.625rem] max-w-[100rem] mx-auto pb-[6.31rem]">
          <div className="flex flex-col items-center mt-[1.5rem] lg:mt-[2.5rem]">
            <h1 className="text-headingLargeSmall">
              Bilen du försökte hitta finns inte längre tillgänglig.
            </h1>
            <h1 className="text-headingLargeSmall">
              Var vänlig försök igen med en annan bil.
            </h1>
            <Button
              sx={{
                color: "#1D818E",
                textTransform: "none",
                fontSize: "1.125rem",
                fontWeight: "600",
                lineHeight: "1.75rem",
              }}
              variant="text"
              onClick={handleBackToRullaButtonClick}
              startIcon={<ArrowBackIcon />}
            >
              Tillbaka
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="relative w-full">
        <div className="container font-rullaFont pt-[5.625rem] max-w-[100rem] mx-auto pb-[6.31rem]">
          <div className="flex flex-col items-center mt-[1.5rem] lg:mt-[2.5rem]">
            <h1 className="text-headingLargeSmall">
              Något gick fel. Var vänlig försök igen.
            </h1>
            <Button
              sx={{
                color: "#1D818E",
                textTransform: "none",
                fontSize: "1.125rem",
                fontWeight: "600",
                lineHeight: "1.75rem",
              }}
              variant="text"
              onClick={handleBackToRullaButtonClick}
              startIcon={<ArrowBackIcon />}
            >
              Tillbaka
            </Button>
          </div>
        </div>
      </div>
    );
  }
  const vehicle: DetailedVehicle = data.vehicle;

  return (
    <div className="relative w-full">
      <div className="container font-rullaFont pt-[5.625rem] max-w-[100rem] mx-auto pb-[6.31rem]">
        <ImageCarousel imageUrls={vehicle.imageSources} />
        <div className="flex flex-col items-center mt-[1.5rem] lg:mt-[2.5rem]">
          <VehicleTitle
            title={vehicle.title}
            modelYear={vehicle.modelYear}
            gearBoxType={vehicle.gearBoxType}
            fuelType={vehicle.fuelType}
            cityPosition={vehicle.branch.cityPosition}
          />
          <PriceContainer
            pricePerMonth={vehicle.pricePerMonth}
            retailerId={vehicle.retailerId}
            vehicle={vehicle}
          />
          <VehiclePropertiesTable properties={vehicle.enhancedProperties} />
          <VehicleEquipment equipment={vehicle.options ?? []} />
        </div>
        <RetailerBranchInformation
          branch={vehicle.branch}
          retailerId={vehicle.retailerId}
        />
      </div>
      <CommonQuestionsDropDown />
      <div
        style={{
          background: "linear-gradient(159deg, #35B6B2 0%, #1F8E9E 100%)",
        }}
      >
        <BottomBanner />
        <BottomNavMenu />
      </div>
    </div>
  );
}
