import { AdditionalService } from '@rulla/shared';

import {HttpClient as http} from '@newsh/base';
export interface GetAdditionalServicesResponse {
  additionalServices: AdditionalService[];
}

export const AdditionalServiceService = {
  getAdditionalServices: (retailerId: string): Promise<GetAdditionalServicesResponse> => 
  http.get<GetAdditionalServicesResponse>(`/api/additional-service/${retailerId}`).then((res) => res.data)
}
