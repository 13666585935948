import { Skeleton } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

export function VehicleCardSkeleton() {
  const aspectRatio = 253 / 380;

  return (
    <Card
      sx={{ maxWidth: 448, height: "auto", pb: "2", background: "transparent" }}
      square={true}
      elevation={0}
    >
      <CardActionArea>
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: `${aspectRatio * 100}%`,
          }}
        >
          <Skeleton
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="rectangular"
          />
        </div>
      </CardActionArea>
      <CardContent sx={{ px: 0, pt: 0.75 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Skeleton width="80%" />
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems="right"
            justifyContent="flex-end"
          >
            <Skeleton width="40%" />
          </Grid>
        </Grid>
        <Skeleton style={{ marginTop: "0.5rem" }} width="30%" />
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={10} container alignItems="center">
            <Skeleton
              style={{ textAlign: "left", marginRight: "0.38rem" }}
              width="10%"
            />
            <Skeleton
              style={{ textAlign: "center", marginRight: "0.38rem" }}
              width="10%"
            />
            <Skeleton style={{ textAlign: "right" }} width="10%" />
          </Grid>
        </Grid>
        <Skeleton style={{ marginTop: "0.5rem" }} width="30%" />
        <Skeleton width="20%" />
      </CardContent>
      <CardActions sx={{ pl: "0", pt: "0", width: "100%" }}>
        <Skeleton
          variant="rounded"
          style={{
            width: "13.625rem",
            height: "3rem",
            borderRadius: 100,
            lineHeight: "1.5rem",
          }}
        />
        <Skeleton
          variant="rounded"
          style={{
            width: "13.625rem",
            height: "3rem",
            borderRadius: 100,
            lineHeight: "1.5rem",
          }}
        />
      </CardActions>
    </Card>
  );
}
