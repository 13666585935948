import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {
  FilterAndSortRow,
  PaginationIndicator,
  ResponsiveGrid,
  VehicleCardSkeleton,
  VehicleEmbeddedCard,
} from "@rulla/features";
import {
  Facet,
  Filter,
  useFacets,
  useVehicles,
} from "@rulla/shared";

import { TableState } from "@newsh/base";

const PAGE_SIZE = 30;
const SKELETON_COUNT = PAGE_SIZE;
const BILBOLAGET_RETAILER_ID = "cb4b6b34-96ee-406f-91fb-e78373b8b258";

export function BilbolagetEmbeddedVehicleList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);

  const parseTableStateFromURL = useCallback((): TableState => {
    const params = new URLSearchParams(location?.search);
    const sortParam = params.get("orderBy");

    if (sortParam) {
      const [_, sortDirection] = sortParam.split("-");
      return {
        sortColumn: "pricePerMonth",
        sortDirection: sortDirection || "asc",
      } as TableState;
    }

    return {
      sortColumn: "pricePerMonth",
      sortDirection: "asc",
    } as TableState;
  }, [location?.search]);

  const [vehicleTableState, setVehicleTableState] = useState<TableState>(
    parseTableStateFromURL()
  );
  const facetsResponse = useFacets({ enabled: true, retailerId: BILBOLAGET_RETAILER_ID });

  const parseFiltersFromURL = useCallback(
    (facets: Facet[]) => {
      const params = new URLSearchParams(location?.search);

      return facets
        .flatMap((facet) => facet.filters)
        .filter((filter) => {
          const [key, value] = filter.query.split("=");
          const valuesForKey = params.getAll(key);
          return valuesForKey.includes(value);
        });
    },
    [location?.search]
  );

  const constructInitialQuery = (): [string, boolean] => {
    const params = new URLSearchParams(location?.search);
    const sortParam = params.get("orderBy");
    let shouldNavigate = false;
    if (!sortParam) {
      params.append(
        "orderBy",
        `pricePerMonth-${vehicleTableState.sortDirection}`
      );
      shouldNavigate = true;
    }
    return [params.toString(), shouldNavigate];
  };

  const [initialQuery, shouldNavigate] = constructInitialQuery();
  const vehicles = useVehicles(PAGE_SIZE, initialQuery, BILBOLAGET_RETAILER_ID);

  useEffect(() => {
    navigate(`${location.pathname}?${initialQuery}`, { replace: true });
  }, [initialQuery, location.pathname, navigate, shouldNavigate]);

  const vehicleList = useMemo(() => {
    if (vehicles.status !== "success") {
      return [];
    }

    // Flatten the array of pages into a single array
    return vehicles.data.pages.flatMap((page) => page.vehicles);
  }, [vehicles.data?.pages, vehicles.status]);

  const facetList = useMemo(() => {
    if (facetsResponse.status !== "success") {
      return [];
    }

    return facetsResponse.data.facets;
  }, [facetsResponse?.data?.facets, facetsResponse.status]);

  const totalCount = useMemo(() => {
    if (vehicles.status !== "success") {
      return 0;
    }
    return vehicles.data?.pages[0]?.count ?? 0;
  }, [vehicles.data?.pages, vehicles.status]);

  useEffect(() => {
    setVehicleTableState(parseTableStateFromURL());
    if (facetList.length > 0) {
      setSelectedFilters(parseFiltersFromURL(facetList));
    }
  }, [facetList, location.search, parseTableStateFromURL, parseFiltersFromURL]);

  const handleFilterSelect = (filter: Filter) => {
    const [key, value] = filter.query.split("=");
    const params = new URLSearchParams(location?.search);
    params.append(key, value);
    addSortLast(params);
    navigate(`?${params.toString()}`);
  };

  const handleFilterDeselect = (filter: Filter) => {
    const [key, value] = filter.query.split("=");
    const params = new URLSearchParams(location?.search);

    const newValues = params.getAll(key).filter((v) => v !== value);
    params.delete(key);

    newValues.forEach((v) => params.append(key, v));
    addSortLast(params);

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const handleFilterReset = () => {
    const params = new URLSearchParams();
    addSortLast(params);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const handleSortChange = (sortColumn: string) => {
    const params = new URLSearchParams(location?.search);
    const currentDirection = params.get("orderBy")?.split("-")[1];
    const newDirection =
      sortColumn === vehicleTableState.sortColumn && currentDirection === "asc"
        ? "desc"
        : "asc";
    params.set("orderBy", `pricePerMonth-${newDirection}`);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const addSortLast = (params: URLSearchParams) => {
    const sortParam = params.get("orderBy");
    if (sortParam) {
      params.delete("orderBy");
      params.append("orderBy", sortParam);
    }
  };

  const handleShowMoreButtonClicked = () => {
    vehicles.fetchNextPage();
  };

  return (
    <div className="relative w-full bg-main-background font-embeddedFont">
      <div className="relative max-w-[20rem] sm:max-w-[26.25rem] md:max-w-[37.5rem] lg:max-w-[100rem] w-full mx-auto lg:px-[5.625rem] pb-[5.625rem]">
        <div className="pt-6">
          <FilterAndSortRow
            facets={facetsResponse?.data?.facets}
            selectedFilters={selectedFilters}
            onFilterSelect={handleFilterSelect}
            onFilterDeselect={handleFilterDeselect}
            onResetFilters={handleFilterReset}
            tableState={vehicleTableState}
            onSortChange={handleSortChange}
          />
        </div>
        <div className="pt-[3rem]">
          {vehicles.status === "loading" ? (
            <ResponsiveGrid>
              {Array.from(Array(SKELETON_COUNT).keys()).map((i) => (
                <VehicleCardSkeleton key={i} />
              ))}
            </ResponsiveGrid>
          ) : vehicleList.length > 0 ? (
            <ResponsiveGrid>
              {vehicleList.map((vehicle) => (
                <VehicleEmbeddedCard key={vehicle.id} vehicle={vehicle} />
              ))}
            </ResponsiveGrid>
          ) : (
            <h1 className="text-heading4">
              Inga bilar hittades. Var vänlig försök igen.
            </h1>
          )}
        </div>
        <div className="flex flex-col items-center pt-8 sm:pt-11 gap-8 sm:gap-11">
          <PaginationIndicator
            currentAmount={vehicleList.length}
            totalAmount={totalCount}
          />
          {vehicleList.length < totalCount && (
            <Button
              disableElevation
              variant="contained"
              sx={{
                width: "8.375rem",
                height: "3rem",
                color: "#C8372F",
                bgcolor: "#C8372F0D",
                fontFamily: "'Source Sans Pro'",
                "&:hover": {
                  backgroundColor: "#C8372F0D",
                  boxShadow:
                    "0px 0.7px 20px 0 #C8372F05, 0px 0.5px 20px 0 #C8372F08",
                },
                textTransform: "none",
                boxShadow:
                  "0px 0.7px 20px 0 #C8372F05, 0px 0.5px 20px 0 #C8372F08",
              }}
              onClick={handleShowMoreButtonClicked}
            >
              Visa fler
              <ExpandMoreIcon />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
