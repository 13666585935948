import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Branch, EmbeddedUtil } from '@rulla/shared';

interface RetailerInformationProps {
  branch: Branch;
  retailerId: string;
  title?: string | null;
}

export function RetailerBranchInformation({
  branch,
  retailerId,
  title = null
}: RetailerInformationProps) {
  const isEmbedded = EmbeddedUtil.isEmbedded();
  const logoSrc = (): string => {
    switch (retailerId) {
      case "b412d449-a6d7-4e98-bdb4-2940bb02f773":
        return "/rebil_logo.png";
      case "77a372b2-fa9c-4d7c-8d8d-11d804b209fa":
        return "/bildeve_logo.png";
      case "cb4b6b34-96ee-406f-91fb-e78373b8b258":
        return "/bilbolaget_logo.png";
    }

    return "";
  };

  const altText = (): string => {
    switch (retailerId) {
      case "b412d449-a6d7-4e98-bdb4-2940bb02f773":
        return "Logo for Re-bil";
      case "77a372b2-fa9c-4d7c-8d8d-11d804b209fa":
        return "Logo for Bildeve";
      case "cb4b6b34-96ee-406f-91fb-e78373b8b258":
        return "Logo for Bilbolaget";
    }

    return "";
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row mt-[5rem] max-w-screen-fit lg:max-w-screen-lg mx-auto items-center lg:items-start">
        <div className="flex flex-col items-center lg:items-start">
          <Typography sx={{ typography: { xs: "heading4", lg: "heading2" } }}>
            {title ?? branch.name}
          </Typography>
          <div className="flex flex-col lg:flex-row items-center lg:items-end mt-[0.5rem] lg:mt-[1.81rem]">
            <Typography className="max-w-[35.375rem] h-auto my-[1.81rem] px-[1.5rem] lg:px-0 text-center lg:text-left">
              {branch.description}
            </Typography>
            <img
              className="w-1/2 lg:w-1/3 ml-0 lg:ml-auto my-0 lg:my-auto lg:items-end items-center mt-[2rem] lg:mt-auto"
              src={logoSrc()}
              alt={altText()}
            />
          </div>
        </div>
      </div>

      <div
        className={
          `max-w-fit lg:max-w-screen-lg mx-auto my-5 ${isEmbedded ? "text-main-embeddedCore" : "text-main-corePink"} text-paraLargeSemi`
        }
      >
        <Link to={branch.link}>{branch.link}</Link>
      </div>
    </>
  );
}
