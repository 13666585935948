import { ReservationRequest, ReservationService } from '@rulla/features/reservation';
import { QueryCacheKeys, Reservation } from '@rulla/shared';
import { ApiError } from '@rulla/shared/infrastructure';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { HttpErrorResponse } from '@newsh/base';

export type CreateReservationRequestParams = {
  reservationRequest: ReservationRequest
};
export const useCreateReservation = () => {
  const queryClient = useQueryClient();

  return useMutation<Reservation, ApiError, CreateReservationRequestParams>(
    (requestParams) => {
      return ReservationService.createReservation(requestParams.reservationRequest)
        .then(reservation => {
          // Map the startDate string to a Date object
          if (reservation.startDate) {
            reservation.startDate = new Date(reservation.startDate);
          }
          return reservation;
        })
        .catch((error) => {
          throw new ApiError(new HttpErrorResponse(error));
        });
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QueryCacheKeys.Reservation]),
    }
  );
};
