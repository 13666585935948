import { SortDirection } from '@newsh/base';

export const SortHelpers = {
  sortByProperty: <T>(
    prop: keyof T | (keyof T)[] | undefined,
    list: T[],
    direction: SortDirection
  ): T[] => {
    if (!prop) {
      return list;
    }

    const properties = Array.isArray(prop) ? prop : [prop];

    const sorted = Array.from(list).sort((a: any, b: any) => {
      const first = getNestedProp(a, properties);
      const second = getNestedProp(b, properties);

      return (Number.isNaN(Number(first)) ? first : Number(first)) >
      (Number.isNaN(Number(second)) ? second : Number(second))
        ? 1
        : -1;
    });

    return direction === 'asc' ? sorted : sorted.reverse();
  },
};

const getNestedProp = (obj: any, props: any) =>
  props.reduce((object: any, prop: any) => object?.[prop], obj);

export function getHumanReadableSort(column: string, direction: SortDirection): string {
  if (column === 'pricePerMonth') {
    switch (direction) {
      case 'asc':
        return 'Pris, Lägsta till högsta';
      case 'desc':
        return 'Pris, Högsta till lägsta';
      default:
        return '';
    }
  }
  // Add more cases for different columns as needed
  return '';
}
