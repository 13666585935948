import { Link } from "react-router-dom";

export function Conditions() {
  return (
    <div className="container font-rullaFont max-w-[58.54rem] mx-auto py-[8vw] pt-[7rem] lg:pt-0 my-0 lg:my-[10vw]">
      <div className="flex flex-col mx-[1.563rem] lg:mx-0">
        <h1 className="text-headingLargeSmall lg:text-headingLarge">
          Allmäna Villkor.
        </h1>
        <p className="mt-[1.25rem] lg:mt-[3rem] text-paraLargeReg">
          Dessa användarvillkor (”Användarvillkoren”) gäller vid användning av
          marknadsplatsen Rulla månadsvis (manadsvis.rulla.se, även kallat
          “Siten”) och sitens tjänster (“Tjänster”). Rulla månadsvis är en
          marknadsplats som tillhandahålls av Newsh AB, org. nummer 559260-0372.
          Användarvillkoren kan komma att uppdateras från tid till annan. Utöver
          Användarvillkoren regleras din användning av Rulla månadsvis och
          sitens Tjänster av annonsörernas egna villkor och
          personuppgiftspolicys som återfinns på respektive annonsörs hemsida.
          Med “Annonsör” menas de bilhandlare som publicerar sina bilar och
          bilabonnemang på Rulla månadsvis.
        </p>
      </div>
      <div className="flex flex-col items-start gap-[0.5rem] mt-[1.688rem] mx-[1.563rem] lg:mx-0">
        <h2 className="text-heading4Small lg:text-heading4">
          1. Marknadsplats för bilabonnemang
        </h2>
        <p className="text-paraLargeReg">
          Rulla månadsvis är en samlingssida där bilabonnemang från flera olika
          annonsörer listas för dig som användare. Våra annonser skapas av
          bilhandlare, som i dessa villkor refereras till som “Annonsörer” eller
          “Bilhandlare“. Ansvaret för annonsernas innehåll ligger hos dem, och
          Rulla månadsvis har inte något ansvar för annonsinnehållet. Avtalet om
          bilabonnemang sker direkt mellan dig som användare och den aktuella
          Bilhandlaren. Rulla månadsvis är inte ansvariga för hur Bilhandlaren
          levererar de tjänster som avtalats. Om du har konsumentfrågor eller
          undrar kring specifika detaljer kring ditt bilabonnemang bör du vända
          dig direkt till den Bilhandlare som du har ingått avtal med, eller som
          erbjuder den bil du är intresserad av att abonnera på.
        </p>
        <p className="text-paraMidSemi">
          Notera att priserna och erbjudandena på vår hemsida kan förändras.
          Alla priser är angivna i svenska kronor (SEK).
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          2. Immateriella rättigheter
        </h2>
        <p className="text-paraLargeReg">
          Rulla månadsvis innehar de immateriella rättigheterna för den
          bakomliggande programkoden för Tjänsterna och Siten, samt logo och
          grafiskt maner. Övriga varumärken och logotyper som finns på siten ägs
          ej av Rulla månadsvis, de ägs av annonsörerna. Med undantag för privat
          användning är det inte tillåtet att kopiera, reproducera, publicera
          eller distribuera något material eller information från Rulla
          månadsvis utan att först inhämta Rulla månadsvis skriftliga samtycke.
          För användning av automatiserade tjänster och andra metoder för
          systematisk användning eller kopiering av innehåll på
          manadsvis.rulla.se krävs Rulla månadsvis skriftliga samtycke.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          3. Ansvarsbegränsningar
        </h2>
        <p className="text-paraLargeReg">
          Rulla månadsvis garanterar inte en kontinuerlig eller säker tillgång
          till Rulla månadsvis site eller Tjänster. Rulla månadsvis och Tjänster
          kan vara helt eller delvis otillgängliga på grund av underhåll, eller
          annan orsak, och Rulla månadsvis kan inte hållas ansvarig för
          eventuella konsekvenser av detta. Rulla månadsvis kan när som helst
          göra fortlöpande förändringar och förbättringar på siten, och/eller
          avbryta din tillgång till alla eller några av Tjänsterna och/eller
          marknadsplatsen Rulla månadsvis. Detta ska inte anses som ett brott
          mot Rulla månadsvis skyldigheter mot användare. Rulla månadsvis har
          rätt att blockera tredjeparts tillgång till siten utan att detta är
          ett avtalsbrott mot dig som användare.
        </p>
        <p className="text-paraLargeReg">
          Rulla månadsvis är endast en marknadsplats och har ingen kontroll över
          transaktionen mellan dig och våra annonsörer när du ingår
          bilabonnemang med dem efter att ha skickat en förfrågan via vår site.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          4. Ingen bindande bokning
        </h2>
        <p className="text-paraLargeReg">
          När du som konsument köper tjänster via Internet har du som regel 14
          dagars ångerrätt. När du skickar en bokningsförfrågan från Rulla
          månadsvis till en handlare är denna förfrågan inte bindande. Du
          skriver ett bindande avtal med bilhandlaren på plats, efter att
          bilhandlaren kontaktat dig, och Rulla månadsvis är inte part i detta
          avtal.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          5. Tvister
        </h2>
        <p className="text-paraLargeReg">
          Svensk lag ska tillämpas på Rulla månadsvis tjänster och dessa
          Användarvillkor. Tvister ska avgöras av svensk allmän domstol. Du kan
          också vända dig till Allmänna reklamationsnämnden, läs mer på
          <a
            href="https://arn.se"
            target="_blank"
            rel="noopener noreferrer"
            className="text-paraMidSemi pl-1"
          >
            www.arn.se
          </a>
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          6. Behandling av personuppgifter
        </h2>
        <p className="text-paraLargeReg">
          Newsh AB ansvarar för behandlingen av personuppgifter som sker när du
          använder Rulla månadsvis och Tjänster. Rulla månadsvis använder
          cookies och behandlar personuppgifter i enlighet med vad som anges i
          Rulla månadsvis integritetspolicy och i enlighet med tillämplig
          dataskyddslagstiftning. För data- och integritetspolicy, läs
          &quot;<Link style={{fontWeight: "600"}} to={`/integritetspolicy`}>Integritetspolicy</Link>&quot;.
        </p>
        <p className="text-paraLargeReg">
          För att kunna ge dig tillgång till Rulla månadsvis Tjänster samlar vi
          in vissa av dina personuppgifter i enlighet med Rulla månadsvis
          integritetspolicy. På manadsvis.rulla.se kan du uppge ditt namn och
          dina kontaktuppgifter, registrera ditt samtycke till villkor och
          datadelning samt att tillåta annonserande bilhandlare att kontakta dig
          (“Kunduppgifter”). Dina Kunduppgifter kommer att delas med den
          bilhandlare som du väljer att skicka en bilabonnemangs-förfrågan till.
          Efter att ett avtal om bilabonnemang har ingåtts blir du kund hos
          bilhandlaren och Rulla månadsvis kommer inte längre att vara
          personuppgiftsansvarig för dina Kunduppgifter och kommer inte att
          spara uppgifterna för egna ändamål. Dina personuppgifter kommer inte
          att användas i marknadsföringssyften av Rulla månadsvis.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          7. Kontakt
        </h2>
        <p className="text-paraLargeReg">
          Om du har några frågor rörande Användarvillkoren, vänligen kontakta
          Rulla. <br /> Mail:{" "}
          <a className="text-main-coreBlue text-paraMidSemi">info@vhf.se</a>{" "}
          eller ring 031-498020.
        </p>
      </div>
    </div>
  );
}
