import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ErrorDetails, ErrorUtil } from '@rulla/shared';

interface IPageErrorProps {
  error?: Error;
}

export function PageError({ error }: IPageErrorProps) {
  const { state } = useLocation();

  const [errorDetails, setErrorDetails] = useState<ErrorDetails | undefined>();

  useEffect(() => {
    const errorDetails = ErrorUtil.getErrorDetails(error, state);
    setErrorDetails({
      correlationId: errorDetails.correlationId,
      details: errorDetails.details,
      httpStatus: errorDetails.httpStatus,
      reason: errorDetails.reason,
      title: errorDetails.title,
      traceId: errorDetails.traceId,
      uri: errorDetails.uri,
    });
  }, [error, state]);
  
  const getReason = (reason: "ERROR_PAGE_CRASH" | "ERROR_PAGE_NOT_FOUND" | undefined) => {
    switch (reason) {
      case "ERROR_PAGE_CRASH":
        return "Ett oväntat fel har inträffat. Var vänlig försök igen";
      case "ERROR_PAGE_NOT_FOUND":
        return "Sidan du letar efter kunde inte hittas";
      default:
        return "Ett oväntat fel har inträffat. Var vänlig försök igen";
    }
  }

  return (
    <div className="text-md flex flex-grow p-4">
      <div className="container mx-auto xl:mt-20">
        <div className="rounded-t-lg bg-primary-500 px-8 py-4 font-medium text-gray-800 shadow-lg">
          <div className="mb-2 text-xl text-primary-300">
            {errorDetails?.title}
          </div>
          <div className="mb-2 text-4xl font-bold text-white">
            Error {errorDetails?.httpStatus}
          </div>
          <div className="text-primary-300">
            {new Date().toLocaleDateString()}
          </div>
          {errorDetails?.correlationId && (
            <div className="text-primary-300">
              Request Id: {errorDetails?.correlationId}
            </div>
          )}
        </div>
        <div className="rounded-b-lg px-8 py-4 shadow-lg">
          <div className="my-4 text-xl font-bold">Beskrivning</div>
          <div className="mb-4">
            {getReason(errorDetails?.reason)}.
          </div>
          {errorDetails?.uri && (
            <div className="text-xs">
              <span>URL: </span>
              <span className="text-blue-500">{errorDetails.uri}</span>
            </div>
          )}
          {errorDetails?.traceId && (
            <div className="text-xs">
              <span>Trace ID: </span>
              <span className="text-blue-500">{errorDetails.traceId}</span>
            </div>
          )}
          {errorDetails?.details && (
            <pre className="mb-4 overflow-auto bg-gray-100 p-4 text-sm">
              {errorDetails.details}
            </pre>
          )}
          <Link className="block pt-4 underline" to="/">
            Tillbaka till startsidan
          </Link>
        </div>
      </div>
    </div>
  );
}
