import { Reservation } from '@rulla/shared';

import { HttpClient as http } from '@newsh/base';

export type ReservationRequest = {
  additionalServiceIds: string[];
  vehicleId: string;
  retailerId: string;
  calculationIndex: number;
  startDate: Date | null;
  customer: CustomerRequest;
};

export type CustomerRequest = {
  firstName: string;
  familyName: string;
  email: string;
  verificationEmail: string;
  phoneNumber: string;
  hasAcceptedRullaTerms: boolean;
};

export const ReservationService = {
  createReservation: (reservationRequest: ReservationRequest) => http.post<Reservation>(`/api/reservation`, reservationRequest).then((res) => res.data),
};
