import { GetRetailerOptionResponse, RetailerOptionService } from '@rulla/features';
import { ApiError, QueryCacheKeys } from '@rulla/shared';
import { useQuery } from '@tanstack/react-query';

import { HttpErrorResponse } from '@newsh/base';

interface UseRetailerOptionProps {
  retailerId?: string | null;
  enabled: boolean;
}

export const useRetailerOption = ({
  retailerId,
  enabled,
}: UseRetailerOptionProps) => {
  return useQuery<GetRetailerOptionResponse, ApiError>(
    [QueryCacheKeys.RetailerOption, retailerId],
    () => {
      if (!retailerId) {
        return Promise.reject(new Error('Invalid id'));
      }

      return RetailerOptionService.getRetailerOption(retailerId).catch(
        (error) => {
          throw new ApiError(new HttpErrorResponse(error));
        }
      );
    },
    { enabled }
  );
};
