import { Facet } from '@rulla/shared';

import {HttpClient as http} from '@newsh/base';

export interface GetFacetsResponse {
  facets: Facet[];
}

export const FacetService = {
  getFacets: (retailerId?: string): Promise<GetFacetsResponse> =>
    http.get<GetFacetsResponse>(`/api/facets${retailerId ? `/${retailerId}` : ""}`).then((res) => res.data)
}
