import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import { Hidden } from "@mui/material";
import Typography from "@mui/material/Typography";
import { EmbeddedUtil } from '@rulla/shared';

interface PriceProps {
  pricePerMonth: number;
}

export function Price({ pricePerMonth }: PriceProps) {
  return (
    <div className="flex felx-row lg:flex-col items-center lg:items-start">
      <Typography
        sx={{
          typography: { xs: "paraSmallReg", lg: "paraLargeReg" },
        }}
      >
        {EmbeddedUtil.isEmbedded() ? "Månadsvis" : "Rulla månadsvis"}
      </Typography>
      <Hidden lgUp>
        <FiberManualRecord sx={{ fontSize: "0.7rem", px: "0.2rem" }} />
      </Hidden>
      <Typography
        sx={{
          typography: { xs: "paraLargeSemiTeal", lg: "heading5Teal" },
        }}
      >
        {pricePerMonth} kr/mån
      </Typography>
    </div>
  );
}
