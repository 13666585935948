import Carousel from "react-material-ui-carousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import { useMediaQuery } from "@mui/material";
import { EmbeddedUtil } from '@rulla/shared';

interface ImageCarouselProps {
  imageUrls: string[];
}

export function ImageCarousel({ imageUrls }: ImageCarouselProps) {
  const isSmallScreen = useMediaQuery("(max-width: 1000px)");
  const isEmbedded = EmbeddedUtil.isEmbedded();
  const carouselProps: CarouselProps = {
    animation: "slide",
    autoPlay: true,
    indicators: true,
    swipe: true,
    cycleNavigation: true,
    stopAutoPlayOnHover: true,
    duration: 1000,
    navButtonsProps: {
      style: {
        backgroundColor: "#fff",
        color: isEmbedded ? "#C8372F" : "#E9457E",
        boxShadow:
          "0px 2px 5px rgba(0, 0, 0, 0.09) ,0px 2px 14px rgba(0, 0, 0, 0.03), 0px 8px 10px rgba(0, 0, 0, 0.03)",
      },
    },
    indicatorContainerProps: {
      style: {
        textAlign: "center",
      },
    },
    activeIndicatorIconButtonProps: {
      style: {
        color: isEmbedded ? "#C8372F" : "#E9457E",
      },
    },
  };
  return (
    <Carousel
      {...carouselProps}
      sx={
        isSmallScreen
          ? {
              width: "100%", // Full width
              maxHeight: "100%",
              justifyContent: "center",
            }
          : {
              width: " 50rem",
              height: "100%",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }
      }
      navButtonsAlwaysVisible={!isSmallScreen}
    >
      {imageUrls?.map((url, index) => (
        <img
          key={index}
          alt={""}
          src={url}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            height: "100%",
          }}
        />
      ))}
    </Carousel>
  );
}
