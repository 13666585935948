import { GetVehiclesResponse, VehicleService } from "@rulla/features/vehicle";
import { QueryCacheKeys } from "@rulla/shared";
import { ApiError } from "@rulla/shared/infrastructure";
import { useInfiniteQuery } from "@tanstack/react-query";

import { HttpErrorResponse } from "@newsh/base";

export const useVehicles = (pageSize: number, filters?: string, retailerId?: string) =>
  useInfiniteQuery<GetVehiclesResponse, ApiError>(
    [QueryCacheKeys.Vehicles, filters, retailerId],
    ({ pageParam: next }) =>
      VehicleService.getVehicles(next, pageSize, filters, retailerId)
        .then((data) => data)
        .catch((error) => {
          throw new ApiError(new HttpErrorResponse(error));
        }),
    {
      getNextPageParam: (lastPage, _) => {
        return lastPage.next;
      },
    }
  );
