export function CommonQuestions() {
  return (
    <div className="container font-rullaFont max-w-[58.54rem] mx-auto py-[8vw] pt-[7rem] lg:pt-0 my-0 lg:my-[10vw]">
      <div className="flex flex-col mx-[1.563rem] lg:mx-0">
        <h1 className="text-headingLargeSmall lg:text-headingLarge">
          Så här fungerar det
        </h1>
      </div>
      <div className="flex flex-col items-start gap-[0.5rem] mt-[1.688rem] mx-[1.563rem] lg:mx-0">
        <h2 className="text-heading4Small lg:text-heading4">
          Vad är ett bilabonnemang?
        </h2>
        <p className="text-paraLargeReg">
          Med ett bilabonnemang får du tillgång till din egen bil och alla
          bekvämligheter det innebär, men slipper besvären kring att äga en bil.
          Du betalar ett fast pris varje månad och alla kostnader så som
          helförsäkring, skatt, service och däckbyte ingår. Det är ingen
          bindningstid på abonnemanget och alla bilar du ser på denna site är
          leveransklara.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vad ingår i abonnemanget?
        </h2>
        <ul className="list-disc list-inside">
          <li className="text-paraLargeReg">Avtalad bil</li>
          <li className="text-paraLargeReg">
            Eventuella tilläggstjänster som avtalats med bilhandlaren
          </li>
          <li className="text-paraLargeReg">Helförsäkring</li>
          <li className="text-paraLargeReg">Skatt</li>
          <li className="text-paraLargeReg">Service</li>
          <li className="text-paraLargeReg">Besiktningskostnad</li>
          <li className="text-paraLargeReg">Sommar- och vinterdäck</li>
          <li className="text-paraLargeReg">
            Däckbyte 2 ggr/år samt däckhotell
          </li>
          <li className="text-paraLargeReg">Rulla Bildelning</li>
        </ul>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vem får skaffa bilabonnemang?
        </h2>
        <p className="text-paraLargeReg">
          Du behöver vara folkbokförd i Sverige, vara minst 19 år gammal och ha
          innehaft giltigt svenskt körkort i minst ett år. Du måste också bli
          godkänd i bilhandlarens kreditkontroll.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hos vem skaffar jag bilabonnemanget?
        </h2>
        <p className="text-paraLargeReg">
          Rulla Månadsvis är en marknadsplats, och den du skaffar
          bilabonnemanget hos är den lokala bilhandlare du väljer att skriva
          avtal med. Du kan filtrera på ort för att få fram relevant bilhandlare
          i din närhet.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hur skaffar jag ett bilabonnemang?
        </h2>
        <p className="text-paraLargeReg">
          Kolla på rulla.se/manadsvis vilken bil du vill abonnera. Klicka sedan
          på “Skicka bokningsförfrågan” och fyll i kompletterande uppgifter.
          Efter att du skickat förfrågan kommer leverantören av bilen kontakta
          dig via telefon eller mail och ni kommer överens om avtalssignering på
          plats eller digitalt, samt om utlämningstid. Avtalet skrivs mellan dig
          och bilhandlaren.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Kan ett företag skaffa ett bilabonnemang?
        </h2>
        <p className="text-paraLargeReg">
          Ja, ett företag kan skaffa bilabonnemang. Kontakta bilhandlaren för
          företagsfrågor.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vart får jag köra?
        </h2>
        <p className="text-paraLargeReg">
          Du får köra vart du vill inom Sveriges gränser om inte annat avtalats
          med bilhandlaren.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hur långt får jag köra?
        </h2>
        <p className="text-paraLargeReg">
          Begränsningen på hur långt du får köra i månaden skiljer sig mellan
          olika bilhandlare, från 125 mil till 200 mil. De flesta erbjuder extra
          mil som tilläggstjänst. Kontakta aktuell bilhandlare så får du reda på
          vad som gäller hos just dem. Om du kör fler mil än vad som är tillåtet
          i ditt avtal med bilhandlaren tillkommer en kostnad enligt aktuell
          prislista som du hittar i bilhandlarens villkor.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hur säger jag upp abonnemanget?
        </h2>
        <p className="text-paraLargeReg">
          Du säger upp ditt abonnemang genom att kontakta bilhandlaren du har
          avtal med via mail eller telefon. Eftersom betalning görs den första
          dagen i varje månad och betalas i förskott behöver du säga upp ditt
          abonnemang minst 5 dagar innan nästa dragning görs för att inte behöva
          betala även för nästkommande månad. Du måste också återlämna bilen
          innan nästa dragning görs.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Jag vill ha hund i bilen, hur gör jag då?
        </h2>
        <p className="text-paraLargeReg">
          Kontakta bilhandlaren för att undersöka möjligheterna att skriva ett
          justerat avtal.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vem får köra bilen?
        </h2>
        <p className="text-paraLargeReg">
          Föraren behöver vara folkbokförd i Sverige och ha ett giltigt körkort.
          Bilhandlarna kan ha ytterligare begränsningar på vem som får köra
          bilen utöver den som står på bilabonnemanget. Kontakta din lokala
          handlare för att få svar.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vem kontaktar jag om jag får problem?
        </h2>
        <p className="text-paraLargeReg">
          Om du får problem med bilen, eller om en skada uppstår, kontaktar du
          bilhandlaren.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hur betalar jag?
        </h2>
        <p className="text-paraLargeReg">
          För privatkunder sker betalning genom att kundens betal-/kreditkort
          debiteras i förskott varje månad. Eventuella tilläggsavgifter såsom
          parkeringsböter, vägtullar eller liknande debiteras i efterhand och
          administrationsavgifter kan tillkomma. Företagskunder faktureras
          månadsvis.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vad händer om jag får en p-bot?
        </h2>
        <p className="text-paraLargeReg">
          Får användaren av bilen en p-bot eller annan avgift så som tull,
          vägskatt eller liknande kommer den faktiska summan att dras i samband
          med nästa dragning på ditt ordinarie betalsätt tillsammans med en
          administrationsavgift. Se aktuell prislista i leverantörens villkor.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hur fungerar det med försäkring?
        </h2>
        <p className="text-paraLargeReg">
          I abonnemanget ingår helförsäkring. Eventuell självrisk kan variera
          hos olika bilhandlare. Kontakta din lokala handlare för att få reda på
          mer.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Hur anmäler jag en skada på bilen?
        </h2>
        <p className="text-paraLargeReg">
          Om du får en skada på bilen, kontaktar du bilhandlaren som i sin tur
          kontaktar försäkringsbolaget.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          Vad är Rulla-appen för något?
        </h2>
        <p className="text-paraLargeReg">
          När du skaffar ett bilabonnemang ingår tjänsten “Rulla”. Detta är en
          bildelningstjänst där du som både privat- eller företagskund kan boka
          bil precis när du behöver det, oavsett om det är flera dagar eller
          bara ett par timmar. Du löser allt i appen. Boka den tid du behöver,
          hämta bilen, kör, och återlämna när du är klar. Alla våra bilar har
          fasta parkeringsplatser och bränsle, trängselskatt och försäkring
          ingår i priset. Rulla finns på flera orter i Sverige och du som kund
          kan boka alla.
        </p>
      </div>
    </div>
  );
}
