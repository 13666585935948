import { Toaster as Toast } from 'react-hot-toast';

export const Toaster = () => (
  <Toast
    toastOptions={{
      position: 'top-right',
      success: {
        iconTheme: {
          primary: 'white',
          secondary: '#2EA44E',
        },
        style: {
          background: '#2EA44E',
          color: 'white',
        },
      },
      error: {
        iconTheme: {
          primary: 'white',
          secondary: '#D32F2F',
        },
        style: {
          background: '#D32F2F',
          color: 'white',
        },
      },
      loading: {
        style: {
          background: 'rgba(255, 245, 231)',
          color: 'rgba(243, 135, 0)',
        },
      },
    }}
  />
);
