import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { PageError } from '@rulla/pages';

import {ErrorUtil, useLogger} from "./shared";

type Props = { children: React.ReactNode };
export const AppErrorBoundary = ({ children }: Props) => {
  const location = useLocation();
  const logger = useLogger();

  const onError = (error: any) => {
    const errorDetails = ErrorUtil.getErrorDetails(error, location.state);

    logger.logError({
      errorMessage: JSON.stringify(errorDetails),
      message: `${errorDetails.title}`,
      source: "app-error-boundary",
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={onError}
      resetKeys={[location.pathname]}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = ({
  error,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => <PageError error={error} />;
