import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  IconButton,
} from "@mui/material";

type Question = {
  question?: string;
  answer?: ReactElement;
  isOpen?: boolean;
};

export const questions: Question[] = [
  {
    question: "Vad är ett bilabonnemang?",
    answer: (
      <Container style={{ paddingLeft: 0 }} sx={{ typography: "paraLargeReg" }}>
        Med ett bilabonnemang får du tillgång till din egen bil och alla
        bekvämligheter det innebär, men slipper besvären kring att äga en bil.
        Du betalar ett fast pris varje månad och alla kostnader så som
        helförsäkring, skatt, service och däckbyte ingår. Det är ingen
        bindningstid på abonnemanget och alla bilar du ser på denna site är
        leveransklara.
      </Container>
    ),
    isOpen: false,
  },
  {
    question: "Hos vem skaffar jag bilabonnemanget?",
    answer: (
      <Container style={{ paddingLeft: 0 }} sx={{ typography: "paraLargeReg" }}>
        Rulla Månadsvis är en marknadsplats, och den du skaffar bilabonnemanget
        hos är den lokala bilhandlare du väljer att skriva avtal med. Du kan
        filtrera på ort för att få fram relevant bilhandlare i din närhet.
      </Container>
    ),
    isOpen: false,
  },
  {
    question: "Vad ingår i abonnemanget?",
    answer: (
      <Container style={{ paddingLeft: 0 }} sx={{ typography: "paraLargeReg" }}>
        <ul className="list-disc list-inside">
          <li className="text-paraLargeReg">Avtalad bil</li>
          <li className="text-paraLargeReg">
            Eventuella tilläggstjänster som avtalats med bilhandlaren
          </li>
          <li className="text-paraLargeReg">Helförsäkring</li>
          <li className="text-paraLargeReg">Skatt</li>
          <li className="text-paraLargeReg">Service</li>
          <li className="text-paraLargeReg">Besiktningskostnad</li>
          <li className="text-paraLargeReg">Sommar- och vinterdäck</li>
          <li className="text-paraLargeReg">
            Däckbyte 2 ggr/år samt däckhotell
          </li>
          <li className="text-paraLargeReg">Rulla Bildelning</li>
        </ul>
      </Container>
    ),
    isOpen: false,
  },
  {
    question: "Hur skaffar jag ett bilabonnemang?",
    answer: (
      <Container style={{ paddingLeft: 0 }} sx={{ typography: "paraLargeReg" }}>
        Kolla på rulla.se/manadsvis vilken bil du vill abonnera. Klicka sedan på
        “Skicka bokningsförfrågan” och fyll i kompletterande uppgifter. Efter
        att du skickat förfrågan kommer leverantören av bilen kontakta dig via
        telefon eller mail och ni kommer överens om avtalssignering på plats
        eller digitalt, samt om utlämningstid. Avtalet skrivs mellan dig och
        bilhandlaren.
      </Container>
    ),
    isOpen: false,
  },
  {
    question: "Vem får skaffa bilabonnemang?",
    answer: (
      <Container style={{ paddingLeft: 0 }} sx={{ typography: "paraLargeReg" }}>
        Du behöver vara folkbokförd i Sverige, vara minst 19 år gammal och ha
        innehaft giltigt svenskt körkort i minst ett år. Du måste också bli
        godkänd i bilhandlarens kreditkontroll.
      </Container>
    ),
    isOpen: false,
  },
  {
    question: "Kan ett företag skaffa ett bilabonnemang?",
    answer: (
      <Container style={{ paddingLeft: 0 }} sx={{ typography: "paraLargeReg" }}>
        Ja, ett företag kan skaffa bilabonnemang. Kontakta bilhandlaren för
        företagsfrågor.
      </Container>
    ),
    isOpen: false,
  },
];

export function CommonQuestionsDropDown() {
  const navigate = useNavigate();
  const [commonQuestions, setCommonQuestions] = useState<Question[]>(questions);

  const handleClickMoreQuestions = () => {
    navigate("/vanliga-fragor");
  };

  const toggleOpen = (index: number) => () => {
    const newQuestions = [...commonQuestions];
    newQuestions[index].isOpen = !newQuestions[index].isOpen;
    setCommonQuestions(newQuestions);
  };

  const questionRow = (question: Question, index: number) => (
    <Card
      square={true}
      elevation={0}
      sx={{
        width: "100%",
        textAlign: "start",
        borderBottom: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardHeader
        sx={{
          paddingLeft: 0,
          height: "5rem",
          paddingY: "1.5rem",
          cursor: "pointer",
        }}
        titleTypographyProps={{ variant: "paraLargeSemi" }}
        title={question.question}
        onClick={toggleOpen(index)}
        action={
          <IconButton aria-label="expand" size="small">
            {question.isOpen ? (
              <ExpandLessIcon sx={{ fill: "#1D818E" }} />
            ) : (
              <ExpandMoreIcon sx={{ fill: "#1D818E" }} />
            )}
          </IconButton>
        }
      />
      <Collapse in={question.isOpen} timeout="auto" unmountOnExit>
        <CardContent style={{ paddingLeft: 0 }}>{question.answer}</CardContent>
      </Collapse>
    </Card>
  );

  return (
    <div className="container lg:max-w-[100rem] mx-auto px-[1.5rem] lg:px-[5.625rem] py-[4rem] lg:py-[8rem]">
      <h1 className="text-heading4">Så här fungerar det</h1>
      <div className="flex flex-col">
        {commonQuestions.map(
          (question, index) =>
            index % 2 === 0 && (
              <div
                key={index}
                className="flex flex-col lg:flex-row lg:gap-[1.5rem] mt-[2.25rem]"
              >
                <div className="w-full lg:w-[48%]">
                  {questionRow(question, index)}
                </div>
                {commonQuestions[index + 1] && (
                  <div className="w-full lg:w-[48%] mt-[2.25rem] lg:mt-0">
                    {questionRow(commonQuestions[index + 1], index + 1)}
                  </div>
                )}
              </div>
            )
        )}
      </div>
      <Button
        style={{ marginTop: "2.44rem" }}
        endIcon={<ArrowForwardIcon />}
        variant="text"
        onClick={handleClickMoreQuestions}
        sx={{ textTransform: "none", typography: "paraLargeSemiTeal" }}
      >
        Se alla frågor & svar
      </Button>
    </div>
  );
}
