export function BottomBanner() {
  return (
    <div className="container lg:max-w-[100rem] w-full mx-auto px-[1.5rem] lg:px-[5.625rem] h-[30.424]">
      <div className="flex flex-col py-[3.438rem] lg:py-[4.688rem] items-center lg:items-start">
        <img
          className="w-[16.467rem] lg:w-[18.75rem] h-auto mb-[0.938rem] pb-[1.25rem]"
          src={"/rulla_neg_RGB.png"}
        ></img>
        <p className="max-w-lg lg:max-w-[32.463rem] mx-auto lg:mx-0 h-auto mb-[2.5rem] lg:mb-0 text-paraLargeRegSmall lg:text-paraLargeReg text-[#FFF] text-center lg:text-left">
          Svenska Volvo- och Renaulthandlare utvecklar och äger Rulla. Vi är en
          intresseorganisation för svenska återförsäljare av Volvo personbilar,
          lastvagnar och bussar samt av Renault och Dacia personbilar och
          transportbilar. Våra medlemmar finns på ca 265 platser i Sverige och
          servar mer än 1 miljon kunder.
        </p>
      </div>
    </div>
  );
}
