import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { CookieService } from "@rulla/shared";

const COOKIE_CONSENT = "cookie_consent";
const CONSENT_ACCEPTED = "true";
const TAG_MANAGER_ID = "G-LMVXS279VZ";
const TAG_MANAGER_URL = `https://www.googletagmanager.com/gtag/js?id=${TAG_MANAGER_ID}`;
const GTM_ASYNC_SCRIPT_ID = "gtm-async-script";
const GTM_INLINE_SCRIPT_ID = "gtm-inline-script";

interface ConsentContextProps {
  isConsentOpen: boolean;
  openConsent: () => void;
  acceptConsent: () => void;
  declineConsent: () => void;
}

interface ConsentProviderProps {
  children?: ReactNode;
}

const insertGtmScript = () => {
  const asyncScript = document.createElement("script");
  asyncScript.src = TAG_MANAGER_URL;
  asyncScript.async = true;
  asyncScript.id = GTM_ASYNC_SCRIPT_ID;
  document.head.appendChild(asyncScript);

  const inlineScript = document.createElement("script");
  inlineScript.text = `
  window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-LMVXS279VZ');
  `;
  inlineScript.id = GTM_INLINE_SCRIPT_ID;
  document.head.appendChild(inlineScript);
};

const ConsentContext = createContext<ConsentContextProps | undefined>(
  undefined
);

export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const hasSetConsent = (): boolean => {
    return CookieService.get(COOKIE_CONSENT) !== undefined;
  };

  const [isConsentOpen, setIsConsentOpen] = useState(!hasSetConsent());

  useEffect(() => {
    if (document.getElementById(GTM_ASYNC_SCRIPT_ID)) {
      return;
    }
    if (CookieService.get(COOKIE_CONSENT)?.includes(CONSENT_ACCEPTED)) {
      insertGtmScript();
    }
  }, []);

  const acceptConsent = () => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    CookieService.set(COOKIE_CONSENT, CONSENT_ACCEPTED, expires, "/");
    insertGtmScript();
    setIsConsentOpen(false);
  };

  const declineConsent = () => {
    if (CookieService.get(COOKIE_CONSENT)?.includes(CONSENT_ACCEPTED)) {
      document.getElementById(GTM_ASYNC_SCRIPT_ID)?.remove();
      document.getElementById(GTM_INLINE_SCRIPT_ID)?.remove();
    }
    CookieService.deleteAll();
    setIsConsentOpen(false);
  };

  const openConsent = () => {
    setIsConsentOpen(true);
  };

  return (
    <ConsentContext.Provider
      value={{ isConsentOpen, openConsent, acceptConsent, declineConsent }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = () => {
  const context = useContext(ConsentContext);
  if (!context) {
    throw new Error("useConsent must be used within a ConsentProvider");
  }
  return context;
};
