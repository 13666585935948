import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './app/app';
import './tailwind.css'

const base = document.getElementsByTagName('base')[0];
const baseUrl = base ? base.getAttribute('href') : undefined;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error: any) => onGlobalDataError(error, queryClient),
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 20,
      onError: (error: any) => onGlobalDataError(error, queryClient),
    },
  },
});

const onGlobalDataError = (error: any, _: QueryClient) => {
  // If query or mutation returns 404, refetch user session to validate active session
  if (error?.response?.status !== 404) {
    return;
  }
  // return queryClient.invalidateQueries(['UserLogin']);
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={baseUrl || undefined}>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
