import {
  BilbolagetEmbeddedConfirmationPage,
  BilbolagetEmbeddedPageError,
  BilbolagetEmbeddedPageNotFound,
  BilbolagetEmbeddedVehicleForm,
  BilbolagetEmbeddedVehicleInfo,
  BilbolagetEmbeddedVehicleList,
} from "@rulla/embedded-pages";

import { VEHICLE_INFO_PARAMS } from './public-routes';
import { CustomRouteObject } from "./types";
import { createRoutePathWithParams } from "./utils";

export const embeddedPublicRoutes: Array<CustomRouteObject> = [
  {
    path: "/",
    element: <BilbolagetEmbeddedVehicleList />,
  },
  {
    path: createRoutePathWithParams("/fordon", VEHICLE_INFO_PARAMS),
    element: <BilbolagetEmbeddedVehicleInfo />,
  },
  {
    path: "/boka",
    element: <BilbolagetEmbeddedVehicleForm />,
  },
  {
    path: "/bekraftelse",
    element: <BilbolagetEmbeddedConfirmationPage />,
  },
  {
    path: "/error",
    element: <BilbolagetEmbeddedPageError />, //TODO: FIX link back from error to home
  },
  {
    path: "*",
    element: <BilbolagetEmbeddedPageNotFound />,
  },
];
