import * as React from 'react';
import Grid from '@mui/material/Grid';
export interface ResponseGridProps {
  children?: React.ReactNode[];
}
export function ResponsiveGrid({children}: ResponseGridProps) {
  return (
    <Grid
      container
      spacing={{ xs:1.5, md: 2 }}
      columns={{ xs: 1, sm: 8, md: 12 }}
    >
      {children && children.map((child, index) => (
        <Grid item xs={1} sm={4} md={4} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}
