import { GetVehicleResponse, VehicleService } from '@rulla/features';
import { QueryCacheKeys } from '@rulla/shared';
import { ApiError } from '@rulla/shared/infrastructure';
import { useQuery } from "@tanstack/react-query";

import { HttpErrorResponse } from '@newsh/base';

interface UseVehicleProps {
  vehicleId?: string | null;
  enabled: boolean;
}

export const useVehicle = ({
  vehicleId,
  enabled,
}: UseVehicleProps) => {
  return useQuery<GetVehicleResponse, ApiError>(
    [QueryCacheKeys.Vehicle, vehicleId],
    () => {
      if (!vehicleId) {
        return Promise.reject(new Error('Invalid id'));
      }

      return VehicleService.getVehicle( vehicleId ).catch(
        (error) => {
          throw new ApiError(new HttpErrorResponse(error));
        }
      );
    },
    { enabled }
  );
};
