import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { EmbeddedUtil } from '@rulla/shared';
import { format } from "date-fns";

interface VehicleAvatarCardProps {
  avatarSource?: string;
  title: string;
  startDate: Date | null | undefined;
  pricePerMonth: number;
}

export function VehicleAvatarCard({
  avatarSource,
  title,
  startDate,
  pricePerMonth,
}: VehicleAvatarCardProps) {
  const isEmbedded = EmbeddedUtil.isEmbedded();
  return (
    <Card
      sx={{
        maxWidth: "42.75rem",
        maxHeight: "8rem",
        border: "0.063rem solid #EEEEEE",
        borderRadius: "24px",
        mb: "1.75rem",
        "@media (max-width: 600px)": {
          borderTop: "1px solid #EEEEEE",
          borderBottom: "1px solid #EEEEEE",
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          maxWidth: "100%",
        },
      }}
      elevation={0}
    >
      <CardContent sx={{ py: "0" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={2}
            sx={{
              alignItems: "center",
              pl: "24px",
              height: "128px",

              "@media (max-width: 600px)": {
                alignItems: "center",
                pl: 0,
                pr: "0.5rem",
                height: "7.25rem",
              },
            }}
          >
            <Avatar
              sx={{
                width: "65px",
                height: "65px",
                border: isEmbedded ? "0.125rem solid #C8372F" : "0.125rem solid #1D818E",
                "@media (max-width: 600px)": {
                  height: "48px",
                  width: "48px",
                },
              }}
              src={avatarSource ?? ""}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            spacing={0}
            sx={{ height: "80px", alignItems: "center" }}
          >
            <Grid item xs={12} sx={{ display: "inline-flex" }}>
              <Typography
                variant="h2"
                align="left"
                sx={{ pr: "0.2rem", whiteSpace: "nowrap" }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "inline-flex" }}>
              <Typography variant="subtitle1" align="left">
                Önskat startdatum
                <FiberManualRecord sx={{ fontSize: "0.7rem", px: "0.2rem" }} />
              </Typography>
              <div className="hidden min-[401px]:block">
                {startDate && (
                  <Typography variant="h4" align="left">
                    {format(startDate, "dd MMMM yyyy")}
                  </Typography>
                )}
              </div>
              <div className="hidden max-[400px]:block">
                {startDate && (
                  <Typography variant="h4" align="left">
                    {format(startDate, "dd-MM-yyyy")}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sx={{ display: "inline-flex" }}>
              <Typography variant="subtitle1" align="left">
                {isEmbedded ? "Månadsvis" : "Rulla månadsvis"}
                <FiberManualRecord sx={{ fontSize: "0.7rem", px: "0.2rem" }} />
              </Typography>
              <Typography variant="paraLargeSemiTeal" align="left">
                {pricePerMonth} kr/mån
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
