import { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DetailedVehicleProperty, EmbeddedUtil } from '@rulla/shared';

interface VehicleDetailsTableProps {
  properties: Record<string, DetailedVehicleProperty>;
}

function groupByCategory(
  properties: DetailedVehicleProperty[]
): Record<string, DetailedVehicleProperty[]> {
  const grouped: Record<string, DetailedVehicleProperty[]> = {};

  properties.forEach((property) => {
    if (!property) {
      return;
    } // Skip null values
    const categoryName = property.category.name;
    if (!grouped[categoryName]) {
      grouped[categoryName] = [];
    }
    grouped[categoryName].push(property);
  });

  return grouped;
}

export function VehiclePropertiesTable({
  properties,
}: VehicleDetailsTableProps) {
  const propertiesArray = Object.values(properties);
  const groupedProperties = groupByCategory(propertiesArray);
  const sortedCategories = Object.keys(groupedProperties).sort();
  const [selectedCategory, setSelectedCategory] = useState<string>(
    sortedCategories[0]
  );
  const isEmbedded = EmbeddedUtil.isEmbedded();

  const getValue = (property: DetailedVehicleProperty) => {
    if (property.type === "bool") {
      return property.value ? "Ja" : "Nej";
    } else {
      if (property.unit) {
        return `${property.value} ${property.unit}`;
      } else {
        return property.value;
      }
    }
  };

  return (
    <div className=" mt-[2.5rem] flex flex-col">
      <Typography
        sx={{
          typography: { xs: "heading2SmallScreen", lg: "heading2" },
          "@media (max-width: 1040px)": {
            marginX: "1.5rem",
          },
        }}
      >
        Biluppgifter
      </Typography>
      <Typography
        marginTop="0.5rem"
        sx={{
          typography: { xs: "paraMidReg", lg: "paraLargeReg" },
          "@media (max-width: 1040px)": {
            maxWidth: "18.265rem",
            marginX: "1.5rem",
          },
        }}
      >
        Information direkt från Transportstyrelsen och tillverkaren.
      </Typography>
      <div
        className="relative overflow-x-hidden no-scrollbar"
        style={{
          display: "flex",
          overflowX: "auto",
          maxWidth: "100vw",
        }}
      >
        <div className="flex flex-row pb-4 ml-[1.5rem] lg:ml-0 space-x-2.5 gap-1 mt-[1.5rem] lg:min-w-[64rem] max-w-screen-fit lg:max-w-screen-lg mx-auto">
          <div className="flex lg:flex lg:flex-wrap gap-2">
            {sortedCategories.map((categoryName) => (
              <Button
                key={categoryName}
                variant={
                  selectedCategory === categoryName ? "contained" : "text"
                }
                onClick={() => setSelectedCategory(categoryName)}
                sx={{
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  height: "2.5rem",
                  borderRadius: isEmbedded ? "0" : "5rem",
                  backgroundColor: "#fff",
                  color: "#000",
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "600",
                  lineHeight: "1.5rem",
                  letterSpacing: "-0.01rem",
                  padding: "1.25rem",
                  boxShadow:
                    "0px 2px 5px 0px rgba(0, 0, 0, 0.09), 0px 2px 14px 1px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03)",
                  "&.MuiButton-contained": {
                    backgroundColor: isEmbedded ? "#C8372F" : "#1D818E",
                    color: "#fff",
                  },
                }}
              >
                {categoryName}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-[1.5rem] max-w-screen-fit lg:max-w-screen-lg">
        {selectedCategory &&
          groupedProperties[selectedCategory].map(
            (property: DetailedVehicleProperty, index: number) => (
              <div
                className="flex flex-row justify-between border-b border-[#F5F5F5] py-[0.75rem] mx-[1.5rem] lg:mx-0 lg:min-w-100"
                key={index}
              >
                <Typography className="text-left" variant="paraMidReg">
                  {property.name}
                </Typography>
                <Typography
                  className="text-right flex-grow"
                  variant="paraMidReg"
                >
                  {getValue(property)}
                </Typography>
              </div>
            )
          )}
      </div>
    </div>
  );
}
