import { embeddedPublicRoutes } from './embedded-public-routes';
import { publicRoutes } from './public-routes';
import { makeRoute } from './utils';

// const authenticatedRoutes: Array<CustomRouteObject> = [
//   {
//     element: <Shell />,
//     children: [
//       { index: true, element: <Home /> },
//     ],
//   },
// ];

// export const appRoutes = publicRoutes.concat(authenticatedRoutes).map(makeRoute);
export const appRoutes = publicRoutes.map(makeRoute);
export const embeddedAppRoutes = embeddedPublicRoutes.map(makeRoute);
