import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import {
  AdditionalServiceList,
  StepIndicator,
  VehicleAvatarCard,
} from "@rulla/features";
import { ReservationRequest } from "@rulla/features/reservation";
import { Reservation, useCreateReservation, Vehicle } from "@rulla/shared";
import { useAdditionalServices } from "@rulla/shared/hooks/queries/useAdditionalServices";
import { ApiError } from "@rulla/shared/infrastructure";

export function VehicleForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const vehicle: Vehicle = location.state?.vehicle;
  const { data: additionalServices, isLoading: isLoadingAdditionalServices } =
    useAdditionalServices({
      retailerId: vehicle?.retailerId,
      enabled: true,
    });
  const { mutate: createReservation, isLoading } = useCreateReservation();
  const today: Date = new Date();

  const [reservationData, setReservationData] = useState<ReservationRequest>({
    additionalServiceIds: [],
    vehicleId: vehicle?.id,
    retailerId: vehicle?.retailerId,
    calculationIndex: vehicle?.calculationIndex,
    startDate: today,
    customer: {
      firstName: "",
      familyName: "",
      email: "",
      verificationEmail: "",
      phoneNumber: "",
      hasAcceptedRullaTerms: false,
    },
  });

  const isValidEmail = (email: string): boolean => {
    /* eslint-disable no-control-regex, no-useless-escape */
    const regex =
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;
    return regex.test(email);
    /* eslint-enable no-control-regex, no-useless-escape */
  };

  const isValidPhoneNumber = (phoneNumber: string): boolean => {
    const regex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
    return regex.test(phoneNumber);
  };

  const isFormValid: boolean =
    reservationData.customer.firstName !== "" &&
    reservationData.customer.familyName !== "" &&
    reservationData.startDate !== null &&
    reservationData.customer.email !== "" &&
    reservationData.customer.verificationEmail !== "" &&
    reservationData.customer.email ===
      reservationData.customer.verificationEmail &&
    isValidEmail(reservationData.customer.email) &&
    isValidEmail(reservationData.customer.verificationEmail) &&
    reservationData.customer.phoneNumber !== "" &&
    isValidPhoneNumber(reservationData.customer.phoneNumber) &&
    reservationData.customer.hasAcceptedRullaTerms;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (name in reservationData.customer) {
      // This means that the changed value belongs to the Customer object
      setReservationData((prevData) => ({
        ...prevData,
        customer: {
          ...prevData.customer,
          [name]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      // This means that the changed value belongs to the top level of reservationData
      setReservationData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const onCreateReservationSuccess = (reservation: Reservation) => {
    navigate("/bekraftelse", {
      state: { reservation: reservation },
    });
  };
  const onCreateReservationError = (_: ApiError) => {
    toast.error("Något gick fel, vänligen försök igen");
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (isFormValid) {
      createReservation(
        { reservationRequest: reservationData },
        {
          onSuccess: onCreateReservationSuccess,
          onError: onCreateReservationError,
        }
      );
    }
  };

  const handleDateChange = (date: Date | null) => {
    setReservationData((prevData) => ({
      ...prevData,
      startDate: date,
    }));
  };

  const handleAdditionalServiceClick = (id: string) => {
    if (reservationData.additionalServiceIds.includes(id)) {
      setReservationData((prevData) => ({
        ...prevData,
        additionalServiceIds: prevData.additionalServiceIds.filter(
          (serviceId) => serviceId !== id
        ),
      }));
    } else {
      setReservationData((prevData) => ({
        ...prevData,
        additionalServiceIds: [...prevData.additionalServiceIds, id],
      }));
    }
  };

  const getTotalSum = (): number => {
    let totalSum = vehicle.pricePerMonth;
    if (additionalServices && reservationData.additionalServiceIds.length > 0) {
      additionalServices.additionalServices.forEach((service) => {
        if (reservationData.additionalServiceIds.includes(service.id)) {
          totalSum += service.pricePerMonth;
        }
      });
    }
    return totalSum;
  };

  const goBack = () => {
    navigate(-1);
  };

  if (!vehicle) {
    window.location.replace(`/`);
    return <></>;
  }

  return (
    <div className="relative w-full bg-main-background font-rullaFont pt-[5.625rem]">
      <div className="container w-auto md:max-w-[42.75rem] mx-[1.5rem] md:mx-auto">
        <div className="pt-[1.75rem] sm:pt-[1.5rem]">
          <Button
            sx={{
              color: "#1D818E",
              textTransform: "none",
              fontSize: "1.125rem",
              fontWeight: "600",
              lineHeight: "1.75rem",
            }}
            variant="text"
            onClick={goBack}
            startIcon={<ArrowBackIcon />}
          >
            Tillbaka
          </Button>
        </div>
        <div className="flex flex-row items-center mt-[1.58rem] gap-3">
          <StepIndicator label="1" />
          <h3 className="text-h4-reg">Välj önskat startdatum</h3>
        </div>
        <div className="pt-[2rem]">
          <DatePicker
            format="dd-MM-yyyy"
            value={reservationData.startDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="flex flex-row items-center mt-10 gap-3">
          <StepIndicator label="2" />
          <h3 className="text-h4-reg">Dina uppgifter</h3>
        </div>
      </div>
      <div className="pt-[2rem] flex items-center justify-center">
        <VehicleAvatarCard
          title={vehicle.title}
          pricePerMonth={getTotalSum()}
          avatarSource={vehicle.imageSource}
          startDate={reservationData.startDate}
        />
      </div>
      <div className="container w-[20.4375rem] lg:w-[42.75rem] pb-[15rem] mx-auto">
        <form>
          <div>
            <Grid container spacing={"1.5rem"}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  disabled={isLoading}
                  required
                  name="firstName"
                  label="Förnamn"
                  variant="filled"
                  sx={{ width: "20.625rem", height: "3.5rem" }}
                  color="primary"
                  value={reservationData.customer.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  disabled={isLoading}
                  required
                  name="familyName"
                  label="Efternamn"
                  variant="filled"
                  sx={{ width: "20.625rem", height: "3.5rem" }}
                  color="primary"
                  value={reservationData.customer.familyName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
          <div className="pt-[1.75rem] md:pt-[2.25rem] space-y-[1.75rem] sm:space-y-0">
            <Grid container spacing={"1.5rem"}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  disabled={isLoading}
                  required
                  name="email"
                  label="Epostadress"
                  variant="filled"
                  sx={{ width: "20.625rem", height: "3.5rem" }}
                  color="primary"
                  value={reservationData.customer.email}
                  onChange={handleChange}
                  error={
                    !isValidEmail(reservationData.customer.email) &&
                    reservationData.customer.email !== ""
                  }
                  helperText={
                    !isValidEmail(reservationData.customer.email) &&
                    reservationData.customer.email !== ""
                      ? "Invalid email address"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  disabled={isLoading}
                  required
                  name="verificationEmail"
                  label="Verifiera epostadress"
                  variant="filled"
                  sx={{ width: "20.625rem", height: "3.5rem" }}
                  color="primary"
                  value={reservationData.customer.verificationEmail}
                  onChange={handleChange}
                  error={
                    (reservationData.customer.email !==
                      reservationData.customer.verificationEmail ||
                      !isValidEmail(
                        reservationData.customer.verificationEmail
                      )) &&
                    reservationData.customer.verificationEmail !== ""
                  }
                  helperText={
                    (reservationData.customer.email !==
                      reservationData.customer.verificationEmail ||
                      !isValidEmail(
                        reservationData.customer.verificationEmail
                      )) &&
                    reservationData.customer.verificationEmail !== ""
                      ? isValidEmail(reservationData.customer.verificationEmail)
                        ? "Emails do not match"
                        : "Invalid email address"
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </div>
          <div className="pt-[1.75rem] sm:pt-[2.25rem]">
            <TextField
              disabled={isLoading}
              required
              name="phoneNumber"
              label="Telefonnummer"
              variant="filled"
              type="tel"
              sx={{ width: "20.625rem", height: "3.5rem" }}
              color="primary"
              value={reservationData.customer.phoneNumber}
              onChange={handleChange}
              error={
                !isValidPhoneNumber(reservationData.customer.phoneNumber) &&
                reservationData.customer.phoneNumber !== ""
              }
              helperText={
                !isValidPhoneNumber(reservationData.customer.phoneNumber) &&
                reservationData.customer.phoneNumber !== ""
                  ? "Invalid phone number"
                  : ""
              }
            />
          </div>
          <AdditionalServiceList
            additionalServices={additionalServices?.additionalServices}
            isLoading={isLoadingAdditionalServices}
            onAdditionalServiceClick={handleAdditionalServiceClick}
            selectedAdditionalServiceIds={reservationData.additionalServiceIds}
          />
          <div className="pt-[1.75rem] sm:pt-[2.25rem]">
            <FormGroup>
              <FormControlLabel
                sx={{ pointerEvents: "none" }}
                required
                name="hasAcceptedRullaTerms"
                control={
                  <Checkbox
                    sx={{ pointerEvents: "auto" }}
                    checked={reservationData.customer.hasAcceptedRullaTerms}
                    onChange={handleChange}
                  />
                }
                label={
                  <div className="flex flex-wrap">
                    Jag godkänner rullas
                    <Link
                      style={{
                        display: "inline",
                        pointerEvents: "auto",
                        paddingLeft: 3,
                        paddingRight: 3,
                        fontWeight: "600",
                      }}
                      to={`/allmanna-villkor`}
                    >
                      villkor
                    </Link>
                    och
                    <Link
                      style={{
                        display: "inline",
                        pointerEvents: "auto",
                        paddingLeft: 3,
                        paddingRight: 3,
                        fontWeight: "600",
                      }}
                      to={`/integritetspolicy`}
                    >
                      integritetspolicy
                    </Link>
                  </div>
                }
              />
            </FormGroup>
          </div>
          <div className=" pt-[1.75rem] sm:pt-[2.25rem] felx-0 sm:flex justify-center">
            <LoadingButton
              disabled={!isFormValid || isLoading}
              loading={isLoading}
              disableElevation={true}
              sx={{
                width: "20rem",
                height: "3.5rem",
                borderRadius: 100,
                color: "#FEF6F9",
                bgcolor: "#E9457E",
                fontFamily: '"Source Sans Pro"',
                fontSize: "1rem",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "-1",
                "@media (max-width: 600px)": {
                  width: "20.4375rem",
                  height: "3rem",
                },
                "&:hover": {
                  backgroundColor: "#E9457E",
                  boxShadow:
                    "0px 0.7px 20px 0 rgba(233, 69, 126, 0.2), 0px 0.5px 20px 0 rgba(233, 69, 126, 0.3)",
                },
                textTransform: "none",
                boxShadow:
                  "0px 0.7px 20px 0 rgba(233, 69, 126, 0.2), 0px 0.5px 20px 0 rgba(233, 69, 126, 0.3)",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Skicka bokningsförfrågan
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
}
