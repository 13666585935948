import { RetailerOption } from '@rulla/shared';

import { HttpClient as http } from "@newsh/base";

export interface GetRetailerOptionResponse {
  option: RetailerOption;
}

export const RetailerOptionService = {
  getRetailerOption: (retailerId: string): Promise<GetRetailerOptionResponse> =>
    http.get<GetRetailerOptionResponse>(`/api/retailer/${retailerId}`).then((res) => res.data),
};
