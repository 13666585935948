import { Link } from 'react-router-dom';

export function PrivacyPolicy() {
  return (
    <div className="container font-rullaFont max-w-[58.54rem] mx-auto py-[8vw] pt-[7rem] lg:pt-0 my-0 lg:my-[10vw]">
      <div className="flex flex-col mx-[1.563rem] lg:mx-0">
        <h1 className="text-headingLargeSmall lg:text-headingLarge">
          Integritetspolicy.
        </h1>
        <p className="mt-[1.25rem] lg:mt-[3rem] text-paraLargeReg">
          Newsh AB (”Newsh”) har antagit denna integritetspolicy (”Policyn”) i
          syfte att informera dig om hur dina personuppgifter behandlas när du
          använder vår site Rulla månadsvis (manadsvis.rulla.se) och dess
          tjänster. På Rulla månadsvis kan du som konsument se tillgängliga
          bilabonnemang från flera olika bilhandlare och skicka en
          bokningsförfrågan till en aktuell bilhandlare.
        </p>
        <p className="text-paraLargeReg mt-[0.5rem]">
          Policyn har tagits fram i enlighet med EU:s dataskyddsförordning, EU
          2016/679 (”GDPR”). Om du på något vis ska lämna personuppgifter till
          oss på Newsh uppmanar vi dig att först läsa igenom Policyn.
        </p>
      </div>
      <div className="flex flex-col items-start gap-[0.5rem] mt-[1.688rem] mx-[1.563rem] lg:mx-0">
        <h2 className="text-heading4Small lg:text-heading4">
          1. Vem ansvarar för dina personuppgifter?
        </h2>
        <p className="text-paraLargeReg">
          Newsh AB, org.nr 559260-0372, med adress F O Petersons Gata 24, 421 31
          Västra Frölunda, är personuppgiftsansvarig för behandling av dina
          personuppgifter enligt vad som anges i denna Policy. Du är välkommen
          att kontakta oss med frågor hänförliga till personuppgiftsbehandling
          av dina personuppgifter. Vänligen skicka ett e-postmeddelande till{" "}
          <a className="text-main-coreBlue text-paraMidSemi pr:">info@vhf.se</a>{" "}
          eller ring 031-498020. Respektive bilhandlare som tillhandahåller dig
          det fordon som du skickar en bokningsförfrågan till är ett
          självständigt företag och personuppgiftsansvarig för den
          personuppgiftsbehandling som de utför. Newsh ansvarar därför inte för
          den anslutna handlarens personuppgiftsbehandling. <br /> Respektive
          bilhandlare som tillhandahåller dig det fordon som du skickar en
          bokningsförfrågan till är ett självständigt företag och
          personuppgiftsansvarig för den personuppgiftsbehandling som de utför.
          Newsh ansvarar därför inte för den anslutna handlarens
          personuppgiftsbehandling.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          2. Hur dina personuppgifter behandlas
        </h2>
        <h3 className="text-paraLargeHeadSemi">2.1. Bokningsförfrågan</h3>
        <p className="text-paraLargeReg">
          Newsh AB ansvarar för behandlingen av personuppgifter som sker när du
          använder Rulla månadsvis och Tjänster. Rulla månadsvis använder
          cookies och behandlar personuppgifter i enlighet med vad som anges i
          Rulla månadsvis integritetspolicy och i enlighet med tillämplig
          dataskyddslagstiftning. För data- och integritetspolicy, läs
          “Integritetspolicy”.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          I samband med att du skickar en bokningsförfrågan till en bilhandlare
          på vår site behandlar vi dina uppgifter för följande ändamål
        </p>
        <ul
          className="text-paraLargeReg"
          style={{ listStylePosition: "inside", listStyleType: "disc" }}
        >
          <li>
            För att bilhandlaren ska kunna kontakta dig för att diskutera
            bilabonnemanget du skickat en förfrågan på.
          </li>
          <li>
            För att bilhandlaren ska kunna kontrollera att du uppnår villkoren
            för att använda tjänsten såsom ålder och krav på körkort;
          </li>
          <li>
            För att bilhandlaren ska kunna kan komma att inhämta en
            kreditupplysning på dig för att bedöma din förmåga att betala.
          </li>
          <li>För att vi ska kunna skicka ett bekräftelsemail till dig</li>
        </ul>
        <p className="text-paraLargeReg py-[1.688rem]">
          Behandlingen sker med stöd av artikel 6.1 (b) i GDPR, fullgörande av
          avtal där du är avtalspart. Om du företräder/är anställd hos en av
          våra företagskunder sker behandlingen istället på grundval av Newshs
          berättigade intresse, artikel 6.1 (f) i GDPR, att fullgöra avtalet med
          vår kund.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Vilka personuppgifter behandlar vi om dig?
        </h4>
        <p className="text-paraLargeReg">
          Vi behandlar namn, personnummer och kontaktuppgifter till kund
          (telefonnummer, epostadress).
        </p>
        <h4 className="text-paraLargeHeadThin pt-[1.688rem]">
          Vem har tillgång till uppgifterna?
        </h4>
        <p className="text-paraLargeReg">
          Bilhandlaren som tillhandahåller fordonet du skickat en
          bokningförfrågan till, samt våra leverantörer som bidrar till vår
          tjänsteleverans, såsom leverantör av IT-system, server- och
          hostingpartner och kundsupport. Vi har ingått
          personuppgiftsbiträdesavtal med samtliga personuppgiftsbiträden som
          reglerar behandlingen av dina personuppgifter.
        </p>
        <h4 className="text-paraLargeHeadThin pt-[1.688rem]">
          Hur länge sparar vi dina uppgifter?
        </h4>
        <p className="text-paraLargeReg">
          Vi loggar din mailadress i 30 dagar och sedan försvinner den
          automatiskt. Vi sparar inte dina personuppgifter. Personuppgifterna du
          skickar i bokningsförfrågan hanteras av bilhandlaren du skickar dem
          till, och de ansvarar för hanteringen av personuppgifterna.
        </p>
        <h3 className="text-paraLargeHeadSemi pt-[1.688rem]">
          2.2. Kundsupport och övriga kontakter
        </h3>
        <h4 className="text-paraLargeHeadThin">
          Varför och enligt vilken rättslig grund behandlar vi dina
          personuppgifter?
        </h4>
        <p className="text-paraLargeReg pb-[1.688rem]">
          I samband med att du kontaktar oss via mail behandlar vi dina
          personuppgifter för följande ändamål
        </p>
        <ul
          className="text-paraLargeReg"
          style={{ listStylePosition: "inside", listStyleType: "disc" }}
        >
          <li>
            För att hantera, utreda och besvara frågor ställda till oss (oavsett
            i vilken form kommunikationen sker);
          </li>
          <li>
            För att kunna erbjuda dig en god support och kundservice samt följa
            upp ditt ärende och kundnöjdhet.
          </li>
        </ul>
        <p className="text-paraLargeReg py-[1.688rem]">
          Behandlingen sker med stöd av artikel 6.1 (b) i GDPR, fullgörande av
          avtal som du är avtalspart i. Om du företräder/är anställd hos en av
          våra företagskunder sker behandlingen istället på grundval av Newshs
          berättigade intresse, artikel 6.1 (f) i GDPR, att fullgöra avtalet med
          vår kund. Behandlingen sker också med stöd av vårt berättigade
          intresse, artikel 6.1 (f) i GDPR, att hantera frågor rörande
          verksamheten och göra gällande, fastställa och försvara rättsliga
          anspråk.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Vilka personuppgifter behandlar vi om dig?
        </h4>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi behandlar namn och kontaktuppgifter till kund (telefonnummer,
          e-postadress), samt uppgifter som du lämnat i anslutning till
          supportbegäran. Vi behandlar enbart dina kontaktuppgifter och
          information i kommunikation mellan oss.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Vem har tillgång till uppgifterna?
        </h4>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Våra leverantörer som bidrar till vår tjänsteleverans såsom leverantör
          av IT-system, server- och hostingpartner och kundsupport. Vi har
          ingått personuppgiftsbiträdesavtal med samtliga personuppgiftsbiträden
          som reglerar behandlingen av dina personuppgifter. Om nödvändigt för
          hantering av ett kundsupportärende lämnar vi också ut dina uppgifter
          till den handlare som tillhandahåller dig fordonet vid respektive
          bokning. I förekommande fall vägassistansbolag, försäkringsbolag och
          legala ombud.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Hur länge sparar vi dina uppgifter?
        </h4>
        <p className="text-paraLargeReg">
          Vi sparar dina uppgifter så länge vi behöver för att besvara ditt mail
          och dina frågor.
        </p>
        <h3 className="text-paraLargeHeadSemi pt-[1.688rem]">
          2.3. Webbplatsen
        </h3>
        <h4 className="text-paraLargeHeadThin">
          Varför och enligt vilken rättslig grund behandlar vi dina
          personuppgifter?
        </h4>
        <p className="text-paraLargeReg pb-[1.688rem]">
          När du besöker Webbplatsen samlar vi in personuppgifter om dig genom
          användning av cookies, för följande ändamål
        </p>
        <ul
          className="text-paraLargeReg"
          style={{ listStylePosition: "inside", listStyleType: "disc" }}
        >
          <li>För att du ska kunna använda Webbplatsen på avsett sätt;</li>
          <li>
            För att underhålla, förbättra samt analysera användningen av
            Webbplatsen och våra tjänster och därmed kunna optimera
            funktionaliteten och kundupplevelsen;
          </li>
          <li>
            För att analysera och mäta intresset för vår verksamhet och skapa
            statistik.
          </li>
        </ul>
        <p className="text-paraLargeReg py-[1.688rem]">
          För placering av cookies inhämtar vi ditt samtycke (bortsett från
          cookies som är nödvändiga för funktionalitet), se vår <Link style={{fontWeight: "600"}} to={`/cookie-policy`}>cookie-policy</Link>.
          De personuppgifter som vi lyckas samla in genom cookies behandlas med
          stöd av vårt berättigade intresse, artikel 6.1. (f) i GDPR, att få
          information om besökare/användare, ge besökare/användare en bra
          användarupplevelse, bedriva och utveckla vår verksamhet samt hantera
          driftstörningar och förhindra olaglig användning.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Vilka personuppgifter behandlar vi om dig?
        </h4>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Vi behandlar personuppgifter som skapas av cookies, t.ex. IP-adress,
          browser, enhetsinställningar, hur du hittade till Webbplatsen, tid för
          besöket, antalet sidvisningar och navigering, de val du gör.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Vem har tillgång till uppgifterna?
        </h4>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Våra leverantörer såsom leverantör av IT-system, server- och
          hostingpartner. Vi har ingått personuppgiftsbiträdesavtal med samtliga
          personuppgiftsbiträden som reglerar behandlingen av dina
          personuppgifter.
        </p>
        <h4 className="text-paraLargeHeadThin">
          Hur länge sparar vi dina uppgifter?
        </h4>
        <p className="text-paraLargeReg">Se vår cookie policy.</p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          3. Varifrån inhämtar vi dina uppgifter?
        </h2>
        <p className="text-paraLargeReg">
          De flesta personuppgifter uppges av dig själv eller skapas i samband
          med att du skickar en bokningsförfrågan där du uppger dina
          personuppgifter. Vi hämtar vidare personuppgifter från det fordon du
          använder genom våra sociala medier samt i din enhets webbläsare.
        </p>
        <p className="text-paraLargeReg">
          Newsh inhämtar inga uppgifter om dig från externa källor såsom
          kreditupplysningsföretag, personadressregister, Trafikverket (avseende
          trängselskatt och fartkameror), men bilhandlarna kan komma att göra
          det efter att du skickar en bokningsförfrågan. Vi uppmanar dig därför
          att läsa aktuell bilhandlares integritets- och personuppgiftpolicy.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          4. Var lagrar vi dina personuppgifter?
        </h2>
        <p className="text-paraLargeReg">
          Som huvudregel behandlas dina personuppgifter enbart inom EU eller
          EES. I vissa fall kan personuppgifter lämnas ut till en sådan part som
          befinner sig utanför EU eller EES. Vi delar bara personuppgifter med
          företag i tredjeländer som har en adekvat skyddsnivå eller företag som
          genom godkända metoder anses uppnå en adekvat skyddsnivå.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          5. Vad har du för rättigheter enligt GDPR?
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt till tillgång: </strong>
          Du har rätt att begära tillgång till de personuppgifter som vi
          behandlar, samt få information om bland annat ändamålen med
          behandlingen och vilka som mottagit personuppgifterna. Newsh kommer
          att förse dig en kopia på de personuppgifter som behandlas.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt till rättelse: </strong>
          Du har rätt att utan onödigt dröjsmål få dina personuppgifter rättade
          eller, under vissa förutsättningar, begränsade. Om du anser att Newsh
          behandlar personuppgifter om dig som är felaktiga eller ofullständiga
          kan du kräva att få dessa rättade eller kompletterade.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt till radering: </strong>
          Du har även rätt att få dina uppgifter raderade bland annat om de inte
          längre är nödvändiga för ändamålet eller om behandlingen av dem
          baseras på samtycke och detta har återkallats. Det kan dock finnas
          lagkrav, avtalsförhållande eller tvingande berättigade intressen som
          gör att vi inte kan radera dina personuppgifter.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt att invända: </strong>
          Du har rätt att när som helst invända mot behandling av dina
          personuppgifter om den lagliga grunden för behandlingen utgörs av en
          intresseavvägning eller för att utföra en uppgift av allmänt intresse.
          Om du invänder måste vi visa att vi har tvingande berättigat skäl för
          sådan behandling eller att vi behöver personuppgifterna för att
          fastställa, utöva eller försvara rättsliga anspråk. Du har även rätt
          att invända mot automatiserat beslutsfattande (inbegripet profilering)
          under vissa förutsättningar.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt till begränsning: </strong>
          Du har i vissa fall möjlighet att begära att vi begränsar behandlingen
          av dina personuppgifter, bl.a. under den tid vi utreder huruvida dina
          uppgifter är korrekta eller utreder en invändning från dig.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt till dataportabilitet: </strong>
          Du har i visa fall rätt att få ut de personuppgifter som du
          tillhandahållit Newsh i ett strukturerat och maskinläsbart format och
          har rätt att överföra dessa uppgifter till en annan
          personuppgiftsansvarig.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Återkallelse av samtycke: </strong>
          Om behandling av dina personuppgifter sker med stöd av ditt samtycke
          har du rätt att när som helst återkalla samtycket.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Rätt att invända mot direkt marknadsföring: </strong>
          Du har rätt att invända mot att dina personuppgifter används för
          direkt marknadsföring Om du gör detta kommer Newsh inte att fortsätta
          att behandla dina personuppgifter för sådana ändamål. Du kan alltid
          välja att avregistrera dig från nyhetsbrev och utskick från oss genom
          att följa anvisningar i mail.
        </p>
        <p className="text-paraLargeReg pb-[1.688rem]">
          <strong>Lämna klagomål: </strong>
          Om du är missnöjd med hur vi behandlar dina personuppgifter ber vi dig
          att kontakta oss. Du har även rätt att ge in ett klagomål på vår
          personuppgiftsbehandling till Datainspektionen.
        </p>
        <h2 className="text-heading4Small lg:text-heading4 mt-[1.188rem]">
          6. Ändring av policyn
        </h2>
        <p className="text-paraLargeReg pb-[1.688rem]">
          Newsh förbehåller sig rätten att ändra och uppdatera denna Policy. Vid
          materiella ändringar i Policyn eller om befintlig information ska
          behandlas på annat sätt än vad som anges kommer du att informeras på
          lämpligt sätt.
        </p>
        <em className="text-paraLargeReg">Uppdaterad augusti 2023</em>
      </div>
    </div>
  );
}
