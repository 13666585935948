import { Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  AntiforgeryInterceptor,
  ConsentProvider,
  EmbeddedUtil,
  ErrorInterceptor,
  Layout,
  Toaster,
  useScrollToTop,
} from "@rulla/shared";
import sv from "date-fns/locale/sv";

import { appTheme, embeddedTheme } from "../assets/styles/theme";
import { AppErrorBoundary } from "./app-error-boundary";
import { appRoutes, embeddedAppRoutes } from "./routes";

setupInterceptors();

function App() {
  useScrollToTop();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <CssBaseline enableColorScheme />
      <AppErrorBoundary>
        <Toaster />
        {!EmbeddedUtil.isEmbedded() ? (
          <ThemeProvider theme={appTheme}>
            <ConsentProvider>
              <Layout>
                <Routes>{appRoutes}</Routes>
              </Layout>
            </ConsentProvider>
          </ThemeProvider>
        ) : (
          <ThemeProvider theme={embeddedTheme}>
            <Routes>{embeddedAppRoutes}</Routes>
          </ThemeProvider>
        )}
      </AppErrorBoundary>
    </LocalizationProvider>
  );
}

function setupInterceptors() {
  AntiforgeryInterceptor.UseAntiforgeryInterceptors();
  ErrorInterceptor.UseErrorInterceptors();
}

export default App;
