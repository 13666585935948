export interface ContainerProps {
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = props => {
  return (
      <>
        {props.children}
      </>
  );
};
