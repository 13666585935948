import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { useConsent } from "@rulla/shared";

export function ConsentBanner() {
  const { isConsentOpen, acceptConsent, declineConsent } = useConsent();

  return (
    <Slide direction="up" in={isConsentOpen}>
      <div className="bg-main-background bg-gray-200 p-4 fixed bottom-0 w-full shadow-lg z-50">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 md:w-3/4 text-left">
            <Typography variant="paraMidReg">
              Vi använder cookies på vär webbplats. Du kan välja att tillåta
              eller avvisa alla cookies. Läs mer i vår
              <Link className="font-semibold" to="/cookie-policy"> cookie-policy</Link>.
            </Typography>
          </div>
          <div className="flex space-x-4 flex-shrink-0 w-auto">
            <Button
              disableElevation
              variant="contained"
              sx={{
                borderRadius: 100,
                color: "#E9457E",
                bgcolor: "rgba(233, 69, 126, 0.05)",
                "&:hover": { backgroundColor: "rgba(233, 69, 126, 0.05)" },
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "-1",
              }}
              onClick={declineConsent}
            >
              Avvisa alla
            </Button>
            <Button
              disableElevation
              variant="contained"
              sx={{
                borderRadius: 100,
                bgcolor: "#E9457E",
                "&:hover": { backgroundColor: "#E9457E" },
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "-1",
              }}
              onClick={acceptConsent}
            >
              Tillåt alla
            </Button>
          </div>
        </div>
      </div>
    </Slide>
  );
}
