import {
  CommonQuestions,
  Conditions,
  ConfirmationPage,
  CookiePolicy,
  PageError,
  PageNotFound,
  PrivacyPolicy,
  VehicleForm,
  VehicleInfo,
  VehicleList,
} from '@rulla/pages';

import { CustomRouteObject } from "./types";
import { createRoutePathWithParams } from "./utils";

export type VehicleInfoParams = {
  vehicleId: string;
};

export const VEHICLE_INFO_PARAMS: VehicleInfoParams = {
  vehicleId: ":vehicleId",
};

export const publicRoutes: Array<CustomRouteObject> = [
  {
    path: "/",
    element: <VehicleList />,
  },
  {
    path: createRoutePathWithParams("/fordon", VEHICLE_INFO_PARAMS),
    element: <VehicleInfo />,
  },
  {
    path: "/boka",
    element: <VehicleForm />,
  },
  {
    path: "/bekraftelse",
    element: <ConfirmationPage />,
  },
  {
    path: "/allmanna-villkor",
    element: <Conditions />,
  },
  {
    path: "/vanliga-fragor",
    element: <CommonQuestions />,
  },
  {
    path: "/integritetspolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/cookie-policy",
    element: <CookiePolicy />,
  },
  {
    path: "/error",
    element: <PageError />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  }
];
