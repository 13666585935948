import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { EmbeddedUtil } from '@rulla/shared';

interface StepIndicatorProps {
  label: string;
}

export function StepIndicator({
  label,
}: StepIndicatorProps) {
  const isEmbedded = EmbeddedUtil.isEmbedded();
  return (
      <Box className="inline-flex">
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: isEmbedded ? "#C8372F" : "#1D818E",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="paraLargeSemi" component="div" color="white">
            {label}
          </Typography>
        </Box>
      </Box>
  );
}
