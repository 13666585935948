import { useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";

export function BottomNavMenu() {
  const navigate = useNavigate();

  const handleConditionsClick = (): void => {
    navigate(`/allmanna-villkor`);
  };

  const handlePrivacyPolicyClick = (): void => {
    navigate(`/integritetspolicy`);
  };

  return (
    <div className="relative w-full">
      <Paper
        sx={{
          justifyContent: "center",
          "@media (max-width: 999px)": {
            paddingLeft: 0,
          },
        }}
        style={{
          height: "4.125rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid rgba(255,255,255,0.18)",
          background: "transparent",
          color: "#FFF",
        }}
      >
        <div className="flex flex-row gap-[1.25rem] lg:max-w-[100rem] w-full mx-auto px-[1.5rem] lg:px-[5.625rem] justify-center lg:justify-start">
          <Typography
            onClick={handleConditionsClick}
            sx={{ typography: "paraSmallReg", cursor: "pointer" }}
          >
            Allmänna villkor
          </Typography>
          <Typography
            onClick={handlePrivacyPolicyClick}
            sx={{ typography: "paraSmallReg", cursor: "pointer" }}
          >
            Integritetspolicy
          </Typography>
        </div>
      </Paper>
    </div>
  );
}
