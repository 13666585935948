import React from "react";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  // Customizing the default variant types for Typography
  interface TypographyVariants {
    paraLargeSemiTeal: React.CSSProperties;
    paraLargeSemiPink: React.CSSProperties;
    paraSmallSemiTeal: React.CSSProperties;
    paraSmallSemi: React.CSSProperties;
    paraMidSemi: React.CSSProperties;
    paraMidReg: React.CSSProperties;
    paraSmallReg: React.CSSProperties;
    heading2: React.CSSProperties;
    heading2Thin: React.CSSProperties;
    paraLargeReg: React.CSSProperties;
    heading5Teal: React.CSSProperties;
    heading5Pink: React.CSSProperties;
    heading4: React.CSSProperties;
    heading2SmallScreen: React.CSSProperties;
    heading2ThinSmallScreen: React.CSSProperties;
    paraLargeSemi: React.CSSProperties;
    h5Pink: React.CSSProperties;
    h5PinkSmall: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    paraLargeSemiTeal?: React.CSSProperties;
    paraLargeSemiPink?: React.CSSProperties;
    paraSmallSemiTeal?: React.CSSProperties;
    paraSmallSemi?: React.CSSProperties;
    paraMidSemi?: React.CSSProperties;
    paraMidReg?: React.CSSProperties;
    paraSmallReg?: React.CSSProperties;
    heading2?: React.CSSProperties;
    heading2Thin?: React.CSSProperties;
    paraLargeReg?: React.CSSProperties;
    heading5Teal?: React.CSSProperties;
    heading5Pink?: React.CSSProperties;
    heading4?: React.CSSProperties;
    heading2SmallScreen?: React.CSSProperties;
    heading2ThinSmallScreen?: React.CSSProperties;
    paraLargeSemi?: React.CSSProperties;
    h5Pink?: React.CSSProperties;
    h5PinkSmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    paraLargeSemiTeal: true;
    paraSmallSemiTeal: true;
    paraSmallSemiPink: true;
    paraSmallSemi: true;
    paraMidSemi: true;
    paraMidReg: true;
    paraSmallReg: true;
    heading2: true;
    heading2Thin: true;
    paraLargeReg: true;
    heading5Teal: true;
    heading5Pink: true;
    heading4: true;
    heading2SmallScreen: true;
    heading2ThinSmallScreen: true;
    paraLargeSemi: true;
    h5Pink: true;
    h5PinkSmall: true;
  }

  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (portrait phones)
      sm: 600, // Small devices (landscape phones)
      md: 960, // Medium devices (tablets)
      lg: 1000, // Large devices (desktops)
      xl: 1200, // Extra-large devices (large desktops)
    },
  },
  palette: {
    primary: {
      main: "#1D818E",
    },
    background: {
      paper: "#FCFCFC",
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro"',
    h2: {
      fontSize: "0.938rem",
      fontWeight: "700",
      lineHeight: "1.25rem",
      letterSpacing: "-1",
      color: "#000000",
    },
    h3: {
      fontSize: "0.938rem",
      fontWeight: "400",
      lineHeight: "1.25rem",
      letterSpacing: "-1",
      color: "#000000",
      "@media (min-width:600px)": {
        fontSize: "1.125rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        letterSpacing: "-1",
        color: "#000000",
      },
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-1",
      color: "#000000",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        fontWeight: "600",
        lineHeight: "1.5rem",
        letterSpacing: "-1",
        color: "#000000",
      },
    },
    h5Pink: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-1",
      color: "#E9457E",
    },
    h5PinkSmall: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
      letterSpacing: "-1",
      color: "#E9457E",
    },
    subtitle1: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "-1",
      color: "#000000",
      opacity: "50%",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        letterSpacing: "-1",
        color: "#000000",
        opacity: "50%",
      },
    },
    paraLargeSemiTeal: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.00875rem",
      color: "#1D818E",
    },
    paraLargeSemiPink: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.00875rem",
      color: "#E9457E",
    },
    paraLargeSemi: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.00875rem",
    },
    paraSmallSemi: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1rem",
      letterSpacing: "-0.00875rem",
    },
    paraSmallSemiTeal: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1rem",
      letterSpacing: "-1",
      color: "#1D818E",
    },
    paraMidSemi: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
      letterSpacing: "-0.01rem",
    },
    paraMidReg: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
      letterSpacing: "-0.01rem",
    },
    paraSmallReg: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1rem",
      letterSpacing: "-0.00875rem",
    },
    heading2: {
      fontSize: "2rem",
      fontWeight: "700",
      lineHeight: "2.5rem",
      letterSpacing: "-0.02rem",
    },
    heading2SmallScreen: {
      fontSize: "1.25rem",
      fontWeight: "700",
      lineHeight: "1.75rem",
      letterSpacing: "-0.0125rem",
    },
    heading2Thin: {
      fontSize: "2rem",
      fontWeight: "400",
      lineHeight: "2.5rem",
      letterSpacing: "-0.02rem",
    },
    heading2ThinSmallScreen: {
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "-0.0125rem",
    },
    paraLargeReg: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "-0.01125rem",
    },
    heading5Teal: {
      fontSize: "1.5rem",
      fontWeight: "700",
      lineHeight: "2rem",
      letterSpacing: "-0.015rem",
      color: "#1D818E",
    },
    heading5Pink: {
      fontSize: "1.5rem",
      fontWeight: "700",
      lineHeight: "2rem",
      letterSpacing: "-0.015rem",
      color: "#E9457E",
    },
    heading4: {
      fontSize: "2rem",
      fontWeight: "700",
      lineHeight: "2.5rem",
      letterSpacing: "-0.02rem",
    },
  },
  components: {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
  }
});

export const embeddedTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (portrait phones)
      sm: 600, // Small devices (landscape phones)
      md: 960, // Medium devices (tablets)
      lg: 1000, // Large devices (desktops)
      xl: 1200, // Extra-large devices (large desktops)
    },
  },
  palette: {
    primary: {
      main: "#1D818E",
    },
    background: {
      paper: "#FCFCFC",
    },
  },
  typography: {
    fontFamily: 'monsal-gothic, sans-serif',
    h2: {
      fontSize: "0.938rem",
      fontWeight: "700",
      lineHeight: "1.25rem",
      letterSpacing: "-1",
      color: "#000000",
    },
    h3: {
      fontSize: "0.938rem",
      fontWeight: "400",
      lineHeight: "1.25rem",
      letterSpacing: "-1",
      color: "#000000",
      "@media (min-width:600px)": {
        fontSize: "1.125rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        letterSpacing: "-1",
        color: "#000000",
      },
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-1",
      color: "#000000",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        fontWeight: "600",
        lineHeight: "1.5rem",
        letterSpacing: "-1",
        color: "#000000",
      },
    },
    h5Pink: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-1",
      color: "#C8372F",
    },
    h5PinkSmall: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
      letterSpacing: "-1",
      color: "#C8372F",
    },
    subtitle1: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "-1",
      color: "#000000",
      opacity: "50%",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        letterSpacing: "-1",
        color: "#000000",
        opacity: "50%",
      },
    },
    paraLargeSemiTeal: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.00875rem",
      color: "#C8372F",
    },
    paraLargeSemiPink: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.00875rem",
      color: "#C8372F",
    },
    paraLargeSemi: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      letterSpacing: "-0.00875rem",
    },
    paraSmallSemi: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1rem",
      letterSpacing: "-0.00875rem",
    },
    paraSmallSemiTeal: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1rem",
      letterSpacing: "-1",
      color: "#2d2d2d",
    },
    paraMidSemi: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
      letterSpacing: "-0.01rem",
    },
    paraMidReg: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
      letterSpacing: "-0.01rem",
    },
    paraSmallReg: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1rem",
      letterSpacing: "-0.00875rem",
    },
    heading2: {
      fontSize: "2rem",
      fontWeight: "700",
      lineHeight: "2.5rem",
      letterSpacing: "-0.02rem",
    },
    heading2SmallScreen: {
      fontSize: "1.25rem",
      fontWeight: "700",
      lineHeight: "1.75rem",
      letterSpacing: "-0.0125rem",
    },
    heading2Thin: {
      fontSize: "2rem",
      fontWeight: "400",
      lineHeight: "2.5rem",
      letterSpacing: "-0.02rem",
    },
    heading2ThinSmallScreen: {
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "-0.0125rem",
    },
    paraLargeReg: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "-0.01125rem",
    },
    heading5Teal: {
      fontSize: "1.5rem",
      fontWeight: "700",
      lineHeight: "2rem",
      letterSpacing: "-0.015rem",
      color: "#1D818E",
    },
    heading5Pink: {
      fontSize: "1.5rem",
      fontWeight: "700",
      lineHeight: "2rem",
      letterSpacing: "-0.015rem",
      color: "#C8372F",
    },
    heading4: {
      fontSize: "2rem",
      fontWeight: "700",
      lineHeight: "2.5rem",
      letterSpacing: "-0.02rem",
    },
  },
  components: {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    }
  }
});
