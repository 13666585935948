import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Typography from "@mui/material/Typography";
import { EmbeddedUtil } from '@rulla/shared';

interface VehicleTitleProps {
  title: string;
  modelYear: number;
  gearBoxType: string;
  fuelType: string;
  cityPosition: string;
}

export function VehicleTitle({
  title,
  modelYear,
  gearBoxType,
  fuelType,
  cityPosition,
}: VehicleTitleProps) {
  const isEmbedded = EmbeddedUtil.isEmbedded();
  
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-wrap">
        <Typography
          sx={{
            pr: "0.5rem",
            typography: { xs: "heading2SmallScreen", lg: "heading2" },
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </div>
      <div className="flex flex-row pt-[0.5rem]">
        <Typography
          sx={{
            typography: { xs: "paraMidReg", md: "paraLargeReg" },
          }}
        >
          {modelYear}
          <FiberManualRecord sx={{ fontSize: "0.7rem", px: "0.2rem" }} />
          {gearBoxType}
          <FiberManualRecord sx={{ fontSize: "0.7rem", px: "0.2rem" }} />{" "}
          {fuelType}{" "}
          <FiberManualRecord sx={{ fontSize: "0.7rem", px: "0.2rem" }} />
          <PlaceOutlinedIcon sx={{ color: isEmbedded ? "#C8372F" : "#E9457E", fontSize: "1rem" }} />
          {cityPosition}
        </Typography>
      </div>
    </div>
  );
}
