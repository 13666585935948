import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { DetailedVehicle, EmbeddedUtil } from '@rulla/shared';

import { Price } from "./price";

interface PriceContainerProps {
  pricePerMonth: number;
  retailerId: string;
  vehicle: DetailedVehicle;
}

export function PriceContainer({
  pricePerMonth,
  retailerId,
  vehicle,
}: PriceContainerProps) {
  const navigate = useNavigate();
  const isEmbedded = EmbeddedUtil.isEmbedded();
  const handleSendReservationRequestClick = (): void => {
    navigate(`/boka`, {
      state: { vehicle: vehicle, retailerId: retailerId },
    });
  };

  return (
    <div className="flex flex-col items-center lg:flex-row p-4 gap-[1rem] lg:gap-40 mt-[2.5rem] maxWidth-[42.75rem] h-auto lg:maxHeight-[8rem] border-[0.063rem] solid border-[#EEEEEE] rounded-3xl">
      <Price pricePerMonth={pricePerMonth} />
      <Button
        disableElevation={true}
        sx={{
          width: "18.125rem",
          height: "4rem",
          borderRadius: isEmbedded ? 0 : 100,
          bgcolor: isEmbedded ? "#C8372F" : "#E9457E",
          boxShadow:
            "0px 0.33207px 2.21381px 0px rgba(233, 69, 126, 0.04), 0px 0.79801px 5.32008px 0px rgba(233, 69, 126, 0.06), 0px 1.50259px 10.01724px 0px rgba(233, 69, 126, 0.08), 0px 2.68036px 17.86905px 0px rgba(233, 69, 126, 0.10), 0px 5.01331px 33.42209px 0px rgba(233, 69, 126, 0.12), 0px 12px 80px 0px rgba(233, 69, 126, 0.16)",
          "&:hover": { backgroundColor: isEmbedded ? "#C8372F" : "#E9457E" },
          textTransform: "none",
          fontSize: "1.125rem",
          fontWeight: "600",
          lineHeight: "1.75rem",
          letterSpacing: "-0.01125rem",
          "@media (max-width: 1020px)": {
            width: "20.5rem",
            height: "2.5rem",
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "600",
            lineHeight: "1.5rem",
            letterSpacing: "-0.01rem",
            boxShadow: "none",
          },
        }}
        variant="contained"
        onClick={handleSendReservationRequestClick}
      >
        Skicka bokningsförfrågan
      </Button>
    </div>
  );
}
