import { LogLevel, LogService } from '@rulla/shared';
import { AxiosError } from 'axios';

import { HttpClient as http } from '@newsh/base';

const excludeUrls: string[] = [
  '/api/log'
];

export const ErrorInterceptor = {
  UseErrorInterceptors: () => {
    http.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const isInExcludeList = excludeUrls.some(
          (url) => url === error.config?.url
        );

        const shouldLog = !isInExcludeList;

        const errorResponse = {
          configData: error.response?.config.data,
          configHeaders: error.response?.config.headers,
          data: error.response?.data,
          serverHeaders: error.response?.headers,
          status: error.response?.status,
          statusText: error.response?.statusText,
          url: error.response?.config.url,
        };

        if (shouldLog) {
          LogService.log({
            level: LogLevel.Error,
            errorMessage: JSON.stringify(errorResponse).slice(0, 2000),
            message: `Received error response ${errorResponse.url}`,
            source: 'error.interceptor',
          });
        }

        return Promise.reject(error);
      }
    );
  },
};
