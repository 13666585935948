import {
  AdditionalServiceService,
  GetAdditionalServicesResponse,
} from "@rulla/features";
import { QueryCacheKeys } from "@rulla/shared";
import { ApiError } from "@rulla/shared/infrastructure";
import { useQuery } from "@tanstack/react-query";

import { HttpErrorResponse } from '@newsh/base';

interface UseAdditionalServicesProps {
  retailerId?: string | null;
  enabled: boolean;
}

export const useAdditionalServices = ({
  retailerId,
  enabled,
}: UseAdditionalServicesProps) => {
  return useQuery<GetAdditionalServicesResponse, ApiError>(
    [QueryCacheKeys.AdditionalServices, retailerId],
    () => {
      if (!retailerId) {
        return Promise.reject(new Error("Invalid id"));
      }

      return AdditionalServiceService.getAdditionalServices(retailerId).catch(
        (error) => {
          const httpError = new HttpErrorResponse(error);
          if (httpError.status === 404) {
            return { additionalServices: [] };
          }
          throw new ApiError(httpError);
        }
      );
    },
    { enabled }
  );
};
