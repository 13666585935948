import { LogLevel, LogService } from '@rulla/shared';

interface LogParams {
  errorMessage?: string;
  message: string;
  source: string;
}

export const useLogger = () => {
  const logInformation = (params: LogParams) =>
    LogService.log({ ...params, level: LogLevel.Information });

  const logWarning = (params: LogParams) =>
    LogService.log({ ...params, level: LogLevel.Warning });

  const logError = (params: LogParams) =>
    LogService.log({ ...params, level: LogLevel.Error });

  const logFatal = (params: LogParams) =>
    LogService.log({ ...params, level: LogLevel.Fatal });

  return { logInformation, logWarning, logError, logFatal };
};
