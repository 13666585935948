import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { EmbeddedUtil } from '@rulla/shared';

interface VehicleEquipmentProps {
  equipment: string[];
}

export function VehicleEquipment({ equipment }: VehicleEquipmentProps) {
  const [showAllEquipment, setShowAllEquipment] = React.useState(false);
  const isEmbedded = EmbeddedUtil.isEmbedded();

  const onShowAllEquipmentClick = () => {
    setShowAllEquipment(true);
  };

  const displayedEquipment = showAllEquipment
    ? equipment
    : equipment.slice(0, 10);

  return (
    <div className="mt-[2.6rem] lg:mt-[5.06rem] flex flex-col">
      <Typography
        sx={{
          typography: { xs: "heading2SmallScreen", md: "heading2" },
          "@media (max-width: 1040px)": {
            marginX: "1.5rem",
          },
        }}
        marginBottom="1.5rem"
      >
        Utrustning
      </Typography>
      <Grid container spacing="0.5rem" className="min-w-screen-fill lg:min-w-[64rem] max-w-screen-lg mx-0 lg:mx-auto ">
        {displayedEquipment.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Typography
              sx={{
                backgroundColor: "#F5F5F5",
                p: "0.75rem",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5rem",
                letterSpacing: "-0.01rem",
                "@media (max-width: 1040px)": {
                  marginX: "1.5rem",
                },
              }}
            >
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {!showAllEquipment && (
        <Button
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "600",
            lineHeight: "1.5rem",
            letterSpacing: "-0.01rem",
            marginTop: "0.5rem",
            color: isEmbedded ? "#C8372F" : "#1D818E"
          }}
          variant="text"
          onClick={onShowAllEquipmentClick}
          endIcon={
            <AddIcon
              sx={{
                backgroundColor: isEmbedded ? "#C8372F" : "#1D818E",
                borderRadius: "100%",
                color: "#fff",
                width: "2rem",
                height: "2rem",
              }}
            />
          }
        >
          Visa all utrustning
        </Button>
      )}
    </div>
  );
}
