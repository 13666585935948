import { Vehicle } from '@rulla/shared';
import { DetailedVehicle } from '@rulla/shared/models/detailed-vehicle.interface';

import { HttpClient as http, RelativeContinuationPathHeader } from '@newsh/base';
export interface GetVehiclesResponse {
  vehicles: Vehicle[];
  next: string;
  count: number;
}

export interface GetVehicleResponse {
  vehicle: DetailedVehicle;
}

const resolveNextQuery = (pageSize?: number, filters?: string): string => {
  const params = [];

  if (pageSize) {
    params.push(`pageSize=${pageSize}`);
  }
  
  let baseQuery = params.length ? `?${params.join('&')}` : '';
  
  if (filters) {
    baseQuery = baseQuery !== '' ? `${baseQuery}&${filters}` : `?${filters}`;
  }

  return baseQuery;
}


export const VehicleService = {
  getVehicles: (next?: string, pageSize?: number, filters?: string, retailerId?: string): Promise<GetVehiclesResponse> =>
    http.get<GetVehiclesResponse>(next ?? `/api/vehicle${retailerId ? `/retailer/${retailerId}` : ""}${resolveNextQuery(pageSize, filters)}`, 
      {
        headers: {
          [RelativeContinuationPathHeader]: true
        }
      }).then((res) => res.data),
  getVehicle: (vehicleId: string): Promise<GetVehicleResponse> =>
    http.get<GetVehicleResponse>(`/api/vehicle/${vehicleId}`).then((res) => res.data),
}
