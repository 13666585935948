import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { EmbeddedUtil, Facet, Filter } from '@rulla/shared';

import { getHumanReadableSort, TableState } from "@newsh/base";

export interface FilterAndSortRowProps {
  facets: Facet[] | undefined | null;
  selectedFilters: Filter[];
  onFilterSelect: (filter: Filter) => void;
  onFilterDeselect: (filter: Filter) => void;
  onResetFilters: () => void;
  tableState: TableState;
  onSortChange: (sortColumn: string) => void;
}

export function FilterAndSortRow({
  facets,
  selectedFilters,
  onFilterSelect,
  onFilterDeselect,
  onResetFilters,
  tableState,
  onSortChange,
}: FilterAndSortRowProps) {
  const [facetAnchorEl, setFacetAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFacet, setSelectedFacet] = useState<Facet | null>(null);
  const isEmbedded = EmbeddedUtil.isEmbedded();

  const handleFacetDropdownCloseClicked = () => {
    setSelectedFacet(null);
    setFacetAnchorEl(null);
  };

  const handleFacetDropdownOpenedClicked = (
    event: React.MouseEvent<HTMLElement>,
    facet: Facet
  ) => {
    setFacetAnchorEl(event.currentTarget);
    setSelectedFacet(facet);
  };

  const handleFilterChange = (filter: Filter) => {
    if (selectedFilters.some((selectedFilter) => selectedFilter === filter)) {
      onFilterDeselect(filter);
    } else {
      onFilterSelect(filter);
    }
  };

  return (
    <Stack direction="column" spacing={2} paddingTop={2}>
      <Stack direction="row" flexWrap="wrap" rowGap={1} gap={1}>
        {selectedFilters.map((filter) => (
          <Chip
            sx={{
              bgcolor: "#FFF",
              border: 1,
              borderColor: isEmbedded ? "#C8372F" : "#E9457E",
              paddingX: 1,
              paddingY: 2,
              color: isEmbedded ? "#C8372F" : "#E9457E",
              textAlign: "center",
              typography: "paraSmallSemi",
              "&:hover": { bgcolor: isEmbedded ? "#C8372F07" : "#FFEAF1" },
            }}
            key={filter.displayName}
            label={filter.displayName}
            size="small"
            onClick={() => onFilterDeselect(filter)}
            deleteIcon={<ClearIcon sx={{ fill: isEmbedded ? "#C8372F" : "#E9457E" }} />}
            onDelete={() => onFilterDeselect(filter)}
          />
        ))}
        {selectedFilters.length > 0 && (
          <Chip
            sx={{
              bgcolor: "#FFF",
              paddingX: 1,
              paddingY: 2,
              border: 1,
              borderColor: isEmbedded ? "#C8372F" : "#1D818E",
              color: isEmbedded ? "#C8372F" : "#1D818E",
              textAlign: "center",
              typography: "paraSmallSemi",
              "&:hover": { bgcolor: isEmbedded ? "#C8372F07" : "#DFF0F0" },
            }}
            label="Rensa"
            size="small"
            icon={<DeleteOutlineIcon sx={{ fill: isEmbedded ? "#C8372F" : "#1D818E" }} />}
            onClick={() => onResetFilters()}
          />
        )}
      </Stack>
      <Stack
        className="ml-[1.5rem] lg:ml-0"
        direction={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "center", sm: "start" }}
        spacing={1}
        justifyContent="space-between"
      >
        <div
          className="relative overflow-x-hidden no-scrollbar "
          style={{
            display: "flex",
            overflowX: "auto",
            maxWidth: "100vw",
          }}
        >
          <Stack
            className="flex pb-4 ml-[1.5rem] mx-auto sm:ml-1 lg:min-w-[64rem] max-w-screen-fit lg:max-w-screen-lg"
            direction="row"
            spacing={1}
          >
            {facets &&
              facets.map((facet) => (
                <Chip
                  sx={{
                    height: "auto",
                    width: "auto",
                    paddingX: "1rem",
                    paddingY: "0.5rem",
                    borderRadius: "5rem",
                    bgcolor: "#FFF",
                    color: "#000",
                    textAlign: "center",
                    typography: "paraSmallSemi",
                    boxShadow:
                      "0px 2px 5px 0px rgba(0, 0, 0, 0.09), 0px 2px 14px 1px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03)",
                    "&:hover": { bgcolor: isEmbedded ? "#C8372F07" : "#DFF0F0" },
                  }}
                  key={facet.id}
                  label={facet.displayName}
                  size="medium"
                  onClick={(event) =>
                    handleFacetDropdownOpenedClicked(event, facet)
                  }
                  deleteIcon={
                    <KeyboardArrowDownIcon sx={{ fill: isEmbedded ? "#C8372F" : "#1D818E" }} />
                  } // deleteIcon because we want the icon to the right
                  onDelete={(
                    event // onDelete because deleteIcon used
                  ) => handleFacetDropdownOpenedClicked(event, facet)}
                />
              ))}
          </Stack>
        </div>
        <Button
          sx={{ "&:hover": { bgcolor: "transparent" } }}
          variant="text"
          disableRipple={isEmbedded}
          endIcon={
            tableState.sortDirection === "desc" ? (
              <KeyboardArrowDownIcon sx={{color: isEmbedded ? "#C8372F" : "inherit"}} />
            ) : (
              <KeyboardArrowUpIcon sx={{color: isEmbedded ? "#C8372F" : "inherit"}} />
            )
          }
          onClick={() => onSortChange("pricePerMonth")}
        >
          <Stack direction="row" whiteSpace="nowrap" spacing={1}>
            <Typography
              sx={{
                textTransform: "none",
                color: "#000",
                typography: "paraMidSemi",
              }}
            >
              Sortering
            </Typography>
            <Typography
              sx={{
                textTransform: "none",
                color: "#000",
                typography: "paraMidReg",
              }}
            >
              {getHumanReadableSort(
                tableState.sortColumn,
                tableState.sortDirection
              )}
            </Typography>
          </Stack>
        </Button>
      </Stack>
      <Popover
        open={selectedFacet !== null}
        anchorEl={facetAnchorEl}
        onClose={handleFacetDropdownCloseClicked}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={1} p={1}>
          {selectedFacet?.filters.map((filter) => (
            <FormControlLabel
              key={filter.displayName}
              control={
                <Checkbox
                  id={filter.displayName}
                  checked={selectedFilters.includes(filter)}
                  onChange={() => handleFilterChange(filter)}
                />
              }
              label={
                <Stack direction="row" justifyContent="space-between" spacing={1} paddingRight="0.25rem">
                  <Typography
                    sx={{
                      textTransform: "none",
                      color: "#000",
                      typography: "paraMidSmall",
                    }}
                  >
                    {filter.displayName}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "none",
                      color: "rgba(0, 0, 0, 0.5)",
                      typography: "paraMidSmall",
                    }}
                  >
                    {filter.count}
                  </Typography>
                </Stack>
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  width: "100%"
                },
                marginBottom: "4px", // Add some spacing
                borderBottom: "1px solid #e0e0e0", // Visual separation
              }}
            />
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
}
