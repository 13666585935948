import { LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";

export interface PaginationIndicatorProps {
  currentAmount: number;
  totalAmount: number;
}

export function PaginationIndicator({
  currentAmount,
  totalAmount,
}: PaginationIndicatorProps) {
  return (
    <div className="flex flex-col items-center w-48 sm:w-96 gap-2 text-black">
      <Typography variant="paraMidReg">
        Visar {currentAmount} av {totalAmount} bilar
      </Typography>
      <LinearProgress 
        variant="determinate"
        className="w-full"
        color="inherit"
        value={(currentAmount / totalAmount) * 100}
      />
    </div>
  );
}
