import { HttpErrorResponse } from '@newsh/base';

export enum ApiErrorCode {
  RetailerNotFound = 200,
  VehicleNotFound = 210,
  VehicleStale = 211,
  AdditionalServiceNotFound = 220,
}

interface ExtendedHttpErrorResponse extends HttpErrorResponse {
  'x-correlation-id'?: string;
}

export class ApiError {
  detail?: string;
  errorCode?: ApiErrorCode;
  httpStatus?: number;
  title?: string;
  traceId?: string;
  uri?: string;
  correlationId?: string;

  constructor(httpErrorResponse: HttpErrorResponse) {
    this.detail = httpErrorResponse.error?.detail;
    this.errorCode = httpErrorResponse.error?.errorCode;
    this.httpStatus = httpErrorResponse.status as number;
    this.title = httpErrorResponse.error?.title;
    this.traceId = httpErrorResponse.error?.['ext-trace-identifier'];
    this.uri = httpErrorResponse.error?.['ext-request-uri'];
    this.correlationId = (httpErrorResponse as ExtendedHttpErrorResponse)?.['x-correlation-id'];
  }
}
