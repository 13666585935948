import { useNavigate } from "react-router-dom";
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Vehicle } from "@rulla/shared";
export interface VehicleCardProps {
  vehicle: Vehicle;
}

export function VehicleCard({ vehicle }: VehicleCardProps) {
  const navigate = useNavigate();

  const handleReserveButtonClick = (): void => {
    navigate(`/boka`, {
      state: { vehicle: vehicle, retailerId: vehicle.retailerId },
    });
  };

  const handleReadMoreButtonClick = (): void => {
    navigate(
      `/fordon/${vehicle.id}`
    );
  };

  return (
    <Card
      sx={{ maxWidth: 448, height: "auto", pb: "2", background: "transparent" }}
      square={true}
      elevation={0}
    >
      <CardActionArea onClick={handleReadMoreButtonClick}>
        <CardMedia
          sx={{
            height: 336,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          component="img"
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
          src={vehicle.imageSource}
          alt="Vehicle"
        />
      </CardActionArea>
      <CardContent sx={{ px: 0, pt: 0.75 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography
              variant="paraSmallSemiTeal"
              align="left"
              sx={{ whiteSpace: "nowrap" }}
            >
              {vehicle.branch.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems="rigth"
            justifyContent="flex-end"
          >
            <PlaceOutlinedIcon sx={{ color: "#E9457E", fontSize: "1rem" }} />
            <Typography
              maxWidth="auto"
              variant="paraSmallSemi"
              align="right"
              sx={{
                color: "#000",
                whiteSpace: "nowrap",
                overflow: "ellipsis",
                maxWidth: "50%",
              }}
            >
              {vehicle.branch.cityPosition}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="paraMidSemi"
          component="div"
          pt="0.5rem"
          sx={{ whiteSpace: "nowrap", overflow: "ellipsis" }}
        >
          {vehicle.title}
        </Typography>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={10} container alignItems="center">
            <Typography
              variant="paraMidReg"
              align="left"
              sx={{ pr: "0.38rem" }}
            >
              {vehicle.modelYear}
            </Typography>
            <FiberManualRecord sx={{ fontSize: "0.8rem", pr: "0.38rem" }} />
            <Typography
              variant="paraMidReg"
              align="center"
              sx={{ pr: "0.38rem" }}
            >
              {vehicle.gearBoxType}
            </Typography>
            <FiberManualRecord sx={{ fontSize: "0.8rem", pr: "0.38rem" }} />
            <Typography variant="paraMidReg" align="right">
              {vehicle.fuelType}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="paraLargeSemiTeal" component="div" pt="0.5rem">
          {vehicle.pricePerMonth} kr/mån
        </Typography>
        <Typography variant="paraSmallReg" component="div">
          inkl. moms
        </Typography>
      </CardContent>
      <CardActions sx={{ pl: "0", pt: "0", width: "100%" }}>
        <Button
          disableElevation={true}
          sx={{
            width: "13.625rem",
            height: "3rem",
            borderRadius: 100,
            color: "#E9457E",
            bgcolor: "rgba(233, 69, 126, 0.05)",
            "&:hover": { backgroundColor: "rgba(233, 69, 126, 0.05)" },
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "600",
            lineHeight: "1.5rem",
            letterSpacing: "-1",
          }}
          variant="contained"
          onClick={handleReadMoreButtonClick}
        >
          Läs mer
        </Button>
        <Button
          disableElevation={true}
          sx={{
            width: "13.625rem",
            height: "3rem",
            borderRadius: 100,
            bgcolor: "#E9457E",
            "&:hover": { backgroundColor: "#E9457E" },
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "600",
            lineHeight: "1.5rem",
            letterSpacing: "-1",
          }}
          variant="contained"
          onClick={handleReserveButtonClick}
        >
          Boka
        </Button>
      </CardActions>
    </Card>
  );
}
