type Props = {
  // permissions?: {
  //   requiredPermissions: Permission[];
  //   operator: PermissionsOperator;
  // };
  children: React.ReactNode;
};

export const PageBase = (props: Props) => {  
  return <>{props.children}</>;

  // if (!props.permissions) {
  //   return <>{props.children}</>;
  // }

  // return (
  //   <RequiredPermissions
  //     onUnauthorized={
  //       <Navigate
  //         replace
  //         to="/error"
  //         state={{ pageNotFound: true, path: location.pathname }}
  //       />
  //     }
  //     requiredPermissions={props.permissions.requiredPermissions}
  //     operator={props.permissions.operator}
  //   >
  //     <AppErrorBoundary>{props.children}</AppErrorBoundary>
  //   </RequiredPermissions>
  // );
};
