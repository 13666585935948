import { NavMenu } from "@rulla/shell/navigation/nav-menu";

import { Container } from "../../shell/container";
import { ConsentBanner } from '../components';

export interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      <NavMenu />
      <Container>{props.children}</Container>
      <ConsentBanner />
    </>
  );
};
