import axios, { AxiosError } from 'axios';

export interface HttpRequestConfig {
  headers?: any;
  responseType?: 'blob';
  params?: any;
  data?: any;
}

export const HttpClient = {
  delete: <T>(url: string, config?: HttpRequestConfig) => {
    return axios.delete<T>(url, config);
  },
  get: <T>(url: string, config?: HttpRequestConfig) => {
    return axios.get<T>(url, config);
  },
  put: <T>(url: string, data?: any, config?: HttpRequestConfig) => {
    return axios.put<T>(url, data, config);
  },
  post: <T>(url: string, data?: any, config?: HttpRequestConfig) => {
    return axios.post<T>(url, data, config);
  },
  get interceptors() {
    return axios.interceptors;
  },
  set defaultTransformResponse(value: (data: any, headers?: any) => any) {
    axios.defaults.transformResponse = value;
  },
};

export class HttpErrorResponse {
  public status: number | undefined;
  public error: any;

  constructor(error: AxiosError) {
    this.status = error.response?.status;
    this.error = error.response?.data;
  }
}
